import React from 'react';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Portfolio from '../components/Home/Portfolio';
import Hero from '../components/Home/Hero';
import TrustedBySection from '../components/Home/TrustedBy';
import HomeMain from '../components/Home/HomeMain';
import Testimonials from '../components/Home/Testimonial';
import CookiesModal from '../components/General/CookiesModal/CookiesModal'; // Adjust the import path as necessary
import useCookieConsent from '../hooks/useCookieConsent';
import { useNavigate } from 'react-router-dom';

const Home = () => {

    const { isCookiesAccepted, handleAcceptCookies, isLoading } = useCookieConsent();
    const navigate = useNavigate();

    const handleKnowMore = () => {
        navigate('/privacy');
    };

    if (isLoading) {
        return null; // Or you could return a loading spinner
    }

    return (
        <>
            <Header />
            <Hero />
            <TrustedBySection />
            <HomeMain />
            {/* <Intro />
            <Services /> */}
            {/* <Portfolio /> */}
            {/* <Clients />
            <Cta /> */}
            <Footer />
            {!isCookiesAccepted && (
                <CookiesModal
                    showModal={true}
                    onAccept={handleAcceptCookies}
                    onSecondaryButtonPressed={handleKnowMore}
                />
            )}
        </>

    )
}

export default Home;

