import React, { useState } from 'react';
import { formatShortDateIndiaStyle } from '../../utils/dateFormatting';
import ScrollableModal from '../General/ScrollableModal/ScrollableModal';

const TableRowUser = ({ user }) => {

    const MAX_SKILLS_PER_ROW = 4;


    const [modalContent, setModalContent] = useState({ show: false, heading: '', body: '' });

    const handleOpenModal = (heading, body) => {
        setModalContent({ show: true, heading, body });
    };

    const handleCloseModal = () => {
        setModalContent({ ...modalContent, show: false });
    };


    return (
        <>
            <tr key={user.id}>
                <td>{user.fullName}</td>

                <td>
                    <a className="text-heading cu1zo" onClick={() => navigator.clipboard.writeText(user.Email)} >
                        {user.Email}
                    </a>
                </td>

                <td>
                    <a className="text-heading cu1zo" href={`https://api.whatsapp.com/send?phone=${user.Mobile.toString().replace(/-/g, '').replace(/\+/g, '')}`} target="blank" >
                        {user.Mobile}
                    </a>

                </td>
                <td>{user.DateOfBirth ? formatShortDateIndiaStyle(user.DateOfBirth) : 'N/A'}</td>
                <td>{user.Gender}</td>
                <td>{user.XP}</td>
                <td>
                    <div className="selected-tech-stack">
                        {user.Skills.map((tag, index) => (
                            <React.Fragment key={index}>

                                <span className="cfhya cy6kr ch6sm ceip1 cdxuw ce33e c7d26 c9eyc c4vrg ca6yp cww2f comjk cmwpt cv6oq cu1zo" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    {tag}

                                </span>
                                {index % MAX_SKILLS_PER_ROW === MAX_SKILLS_PER_ROW - 1 && <br />}
                            </React.Fragment>
                        ))}
                    </div>
                </td>

                <td> {user.EducationalQualification}</td>
                <td> {user.EducationalInstitute}</td>

                <td>
                    <a className="text-heading font-semibold cu1zo" onClick={() => handleOpenModal('About User', user.AboutMe)}>
                        About
                    </a>
                </td>
                <td>
                    <a className="text-heading font-semibold cu1zo" onClick={() => handleOpenModal('User Experience', user.MyExperience)}>
                        Experience
                    </a>
                </td>
                <td>
                    <a className="text-heading font-semibold cu1zo" onClick={() => handleOpenModal('Past Projects', user.PastProjects)}>
                        Projects
                    </a>
                </td>

                <td>{`${user.city}, ${user.state}, ${user.country}`}</td>
            </tr>

            <ScrollableModal
                show={modalContent.show}
                heading={modalContent.heading}
                body={modalContent.body}
                onClose={handleCloseModal}
                showButtons={false} // Assuming no buttons are needed in the modal
            />

        </>
    );
};

export default TableRowUser;