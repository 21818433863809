import React from 'react';
import point from "../../images/points.png";
import { useNavigate } from 'react-router-dom';
import Chip from '../LabelChip/LabelChip';
import PriceIndicator from '../PriceIndicator/PriceIndicator';
import "../../pages/Home.css"
import arrow from "../../images/right-arrow.svg"

const JobCard = ({ jobID, jobTitle, tags, chipColor, priceRating, points, minPoints, isPromoted, projectType }) => {

    const navigate = useNavigate();

    return (
        <div className='job-card job-card-hover' style={{ position: 'relative' }}>


            {isPromoted && (
                <div className="promoted-ribbon">
                    FEATURED
                </div>
            )}


            <div className="cokkt czhnl c6kou cmd8n">
                <div className="cwkz1 c0qn4">
                    <div className="cduop cb7bz cp1au c6rln cclz8">
                        {/* Implement this feature of showing company logo when we have money to afford more cloud storage */}
                        {/* <div className="ctzpc">
                            <img src={company.logo} width="56" height="56" alt={`${company.name} logo`} />
                        </div> */}
                        <div className="cycys cduop chu3i cyhe5 cb04d c966h clhjg">
                            <div>


                                <div className="cmi0k">
                                    <a className="cqdqx clt06 cznhr cu1zo" onClick={() => navigate(`/job-detail/${jobID}`)}>
                                        {jobTitle}
                                    </a>
                                </div>

                                <div className='job-card-badges'>

                                    <div className="c8og8 c5li5 ccgu6">
                                        <div className="c3774 cznhr" style={{ marginRight: '30px' }}>Min XP: <b>{minPoints}</b></div>
                                        <div class="project-type-chip">
                                            <b>{projectType}</b>
                                        </div>

                                    </div>

                                    {/* New row with Chip, Price Indicator, and Icon with Text */}
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 0', width: '100%' }}>
                                        {/* Chip Component */}
                                        <Chip text="Sample" color={chipColor} />

                                        {/* Price Indicator Component */}
                                        <PriceIndicator price={priceRating} />

                                        {/* Point Icon with Text */}
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={point} alt="Points Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                            <span style={{ fontSize: '14px', fontWeight: '600' }}>{points}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="c0zhl" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                    {tags.map((tag, index) => (
                                        <a key={index} className="cfhya cy6kr ch6sm ceip1 cdxuw ce33e c7d26 c9eyc c4vrg ca6yp cww2f comjk cmwpt cv6oq" href="#0" style={{ marginRight: '8px' }}>
                                            {tag}
                                        </a>
                                    ))}
                                </div>
                            </div>



                            <div className="c8og8 co7yr cduop c40vg c16gq c56h7">
                                <div className="ct4vx cjjz8">
                                    <button
                                        className="c40tu ce7qj c50eh c2fn4 cyky3 ctpla czwz3 c6kou"
                                        onClick={() => navigate(`/job-detail/${jobID}`)}
                                    >
                                        Apply Now <span className="ce3ah ccolo cndda clfy9 comjk cmwpt c8bku">→</span>
                                    </button>
                                </div>
                                {/* <div className="cf37z c75bs ca6yp c2vtk">{timePosted}</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobCard;
