import React from 'react';

const AdminNav = ({ activeTab, handleTabChange }) => (
    <ul className="nav nav-tabs mt-4 overflow-x border-0">
        {['Applications', 'Pending', 'Ongoing', 'Project Requests', 'Users'].map(tab => (
            <li className="nav-item" key={tab}>
                <a className={`nav-link cu1zo ${activeTab === tab ? 'active' : ''}`} onClick={() => handleTabChange(tab)}>
                    {tab}
                </a>
            </li>
        ))}
    </ul>
);

export default AdminNav;