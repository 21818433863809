import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HamburgerButton.css';

const HamburgerButton = ({ isLoggedIn, onSignInClick, onLogOutClick }) => {

    const navigate = useNavigate();

    const handleProfileClick = () => {
        if (isLoggedIn) {
            navigate('/profile');
        } else {
            navigate('/signin');
        }
    };

    const handlePostProjectClick = () => {
        if (isLoggedIn) {
            navigate('/list-job');
        } else {
            navigate('/signin');
        }
    };


    return (
        <label className="hamburger-button-popup">
            <input type="checkbox" className="hamburger-button-checkbox" />
            <div className="hamburger-button-burger" tabIndex="0">
                <span className="hamburger-button-line"></span>
                <span className="hamburger-button-line"></span>
                <span className="hamburger-button-line"></span>
            </div>
            <nav className="hamburger-button-popup-window">
                {/* <legend className="hamburger-button-legend">Actions</legend> */}
                <ul className="hamburger-button-list">

                    <li className="hamburger-button-item">
                        <button className="hamburger-button-action" onClick={handleProfileClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                            </svg>
                            <span>Profile</span>
                        </button>
                    </li>

                    <li className="hamburger-button-item">
                        <button className="hamburger-button-action" onClick={isLoggedIn ? onLogOutClick : onSignInClick}> {/* Added onClick handlers */}
                            {isLoggedIn ? ( // Conditional rendering based on isLoggedIn
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z" />
                                        <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z" />
                                    </svg>
                                    <span>Log out</span>
                                </>
                            ) : (
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z" />
                                        <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                                    </svg>
                                    <span>Sign in</span>
                                </>
                            )}
                        </button>
                    </li>


                    <li className="hamburger-button-item">
                        <button className="hamburger-button-action" onClick={handleProfileClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send-fill" viewBox="0 0 16 16">
                                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                            </svg>
                            <span>Post Project</span>
                        </button>
                    </li>


                    {/* <hr className="hamburger-button-divider" /> */}
                    {/* <li className="hamburger-button-item">
                        <button className="hamburger-button-action">
                            <svg
                                strokeLinejoin="round"
                                strokeLinecap="round"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                                height="14"
                                width="14"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect ry="2" rx="2" height="13" width="13" y="9" x="9"></rect>
                                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                            </svg>
                            <span>Clone</span>
                        </button>
                    </li>
                    <li className="hamburger-button-item">
                        <button className="hamburger-button-action">
                            <svg
                                strokeLinejoin="round"
                                strokeLinecap="round"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                                height="14"
                                width="14"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon>
                            </svg>
                            <span>Edit</span>
                        </button>
                    </li>
                    <hr className="hamburger-button-divider" />
                    <li className="hamburger-button-item">
                        <button className="hamburger-button-action">
                            <svg
                                strokeLinejoin="round"
                                strokeLinecap="round"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                                height="14"
                                width="14"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <line y2="18" x2="6" y1="6" x1="18"></line>
                                <line y2="18" x2="18" y1="6" x1="6"></line>
                            </svg>
                            <span>Delete</span>
                        </button>
                    </li> */}
                </ul>
            </nav>
        </label>
    );
};

export default HamburgerButton;
