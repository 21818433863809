//Main Imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Backend Imports

import { onAuthStateChanged } from 'firebase/auth';
import { addDoc, setDoc, collection, query, orderBy, limit, getDocs, getDoc, deleteDoc, updateDoc, doc, writeBatch, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../firebaseConfig/Firebase';

//Frontend Imports

import logo from '../images/logo2.svg';
import { toast } from 'react-toastify';
import Modal from '../components/General/Modal/Modal';
import CookiesModal from '../components/General/CookiesModal/CookiesModal';
import Loader from '../components/Loader/Loader';

// Component Imports

import SideTestimonial from '../components/SideTestimonials/SideTestimonials';
import SkillsDropdown from '../components/SkillsDropdown/SkillsDropdown';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

//Hooks
import useCheckUserSignin from '../hooks/useCheckUserSignin';
import useCookieConsent from '../hooks/useCookieConsent';


// Data Imports
import { skillOptions } from '../data/data';
import { sideTestimonialListJobData } from '../data/data';
import { maxLengthChecksForListjob } from '../data/data';
import { dummyDataforProjectListing } from '../data/dummyData';
import { projectRoleTypeData } from '../data/data';


// Function Imports
import { generateUniqueId } from '../utils/otherFunctions';
import { validateListJobInputs } from '../utils/listjobValidator';
import { countDigits, renderError } from '../utils/otherFunctions';
import { uploadFile } from '../utils/uploadFile';



const ListJob = () => {

    const navigate = useNavigate();
    useCheckUserSignin(navigate);

    const [stick, setStick] = useState(false);
    const [highlight, setHighlight] = useState(false);
    const [selectedTechStack, setSelectedTechStack] = useState([]);
    const [noFixedBudget, setNoFixedBudget] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalHeading, setModalHeading] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const { isCookiesAccepted, handleAcceptCookies } = useCookieConsent();


    // state variables for form inputs

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [projectTitle, setProjectTitle] = useState('');
    const [projectType, setProjectType] = useState('');
    const [description, setDescription] = useState('');
    const [requirements, setRequirements] = useState('');
    const [responsibilities, setResponsibilities] = useState('');
    const [budget, setBudget] = useState('');

    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [phoneError, setPhoneError] = useState('');



    // Input field collection in this page
    const listJobInputFields = {
        name,
        email,
        mobile,
        projectTitle,
        projectType,
        description,
        requirements,
        responsibilities,
    }


    const uploadDummyData = async () => {
        setIsLoading(true);
        try {
            for (const project of dummyDataforProjectListing) {
                const docId = project.Email;

                await setDoc(doc(db, "Users", docId), project);
            }
            toast.success("Dummy data uploaded successfully.");
        } catch (error) {
            console.error("Error uploading dummy data: ", error);
            toast.error("An error occurred while uploading dummy data.");
        } finally {
            setIsLoading(false);
        }
    };

    const deleteTopSevenDocuments = async () => {
        setIsLoading(true);
        try {
            console.log("Starting to delete top 7 documents...");
            const projectsRef = collection(db, "Projects");
            const q = query(projectsRef, orderBy("createdAt", "desc"), limit(7));
            const querySnapshot = await getDocs(q);

            const batch = writeBatch(db);
            querySnapshot.forEach((docSnapshot) => {
                console.log(`Deleting document with ID: ${docSnapshot.id}`);
                batch.delete(docSnapshot.ref); // Correctly use the document reference
            });

            await batch.commit();
            console.log("Batch commit successful.");
            toast.success("Top 7 documents deleted successfully.");
        } catch (error) {
            console.error("Error deleting documents: ", error);
            toast.error("An error occurred while deleting documents.");
        } finally {
            setIsLoading(false);
        }
    };

    const updateIsStickPostonTopField = async () => {
        setIsLoading(true);
        try {
            console.log("Starting to update 'isStickPostonTop' field...");
            const projectsRef = collection(db, "Users");
            const querySnapshot = await getDocs(projectsRef);

            const batch = writeBatch(db);
            querySnapshot.forEach((docSnapshot) => {
                console.log(`Updating document with ID: ${docSnapshot.id}`);
                batch.update(docSnapshot.ref, { DateOfBirth: Timestamp.fromDate(new Date()) });
            });

            await batch.commit();
            console.log("Batch update successful.");
            toast.success("'isStickPostonTop' field updated successfully for all documents.");
        } catch (error) {
            console.error("Error updating documents: ", error);
            toast.error("An error occurred while updating documents.");
        } finally {
            setIsLoading(false);
        }
    };

    // Loader Logic

    if (isLoading) {
        return <Loader />;
    }

    //Basic frontend Logic

    const handlePhoneChange = (value, country) => {
        const formattedMobile = `+${country.dialCode}-${value.slice(country.dialCode.length)}`;
        const digitsCount = countDigits(value.slice(country.dialCode.length));
        if (digitsCount < 10) {
            setPhoneError('Invalid Phone Number');
        } else {
            setPhoneError('');
        }
        console.log(countDigits(value.slice(country.dialCode.length)))
        setMobile(formattedMobile);
    };

    const renderErrorMessage = (field) => renderError(listJobInputFields, field, maxLengthChecksForListjob);


    //Logic to submit the data for Firestore Database

    const handleSubmit = async (e) => {
        e.preventDefault();


        if (phoneError !== '') {
            toast.error('Invalid phone number');
            return;
        } else {
            setIsLoading(true);
        }



        const truncatedTitle = projectTitle.slice(0, 25).trim().replace(/\s/g, "-");
        const uniqueId = generateUniqueId();
        const docId = `${truncatedTitle}---${uniqueId}`;


        let uploadedFileUrl = '';
        if (file) {
            try {
                uploadedFileUrl = await uploadFile();
            } catch (error) {
                console.error("Error uploading file: ", error);
                toast.error("An error occurred while uploading the file.");
                setIsLoading(false);
                return;
            }
        }


        if (fileError) {

            toast.error("File uploaded is too big, please remove it or upload a smaller file");
            setIsLoading(false);
            return;
        }

        if (!agreedToTerms) {
            toast.info('Agree to the terms & condition and privacy policy in order to post the project');
            return;
        }

        const projectData = {

            ...listJobInputFields,
            techStack: selectedTechStack,
            budget: noFixedBudget || budget === '' ? -1 : budget,
            isStickPostonTop: stick,
            isNotifyPostonEmail: highlight,
            createdAt: new Date(),
            fileUrl: uploadedFileUrl,
            isDeletedByAdmin: false,
            points: 0,
            minXP: 0,
            status: 'Unverified',
            selectedApplicants: [],
            removedApplicants: [],
            moderators: [],

        };

        if (!validateListJobInputs({

            ...listJobInputFields,
            selectedTechStack,
            budget
        }, selectedTechStack)) {
            setIsLoading(false);
            return;
        }

        try {
            await setDoc(doc(db, "Projects", docId), projectData);
            setModalHeading("Project Submitted Successfully");
            setModalMessage("Your project requirement has been submitted and will be reviewed by our team, you will soon be contacted by someone from our team.");
            setShowModal(true);
        } catch (error) {
            console.error("Error adding document: ", error);
            toast.error("An error occurred while posting the project.");
        } finally {
            setIsLoading(false);
        }
    };

    // File Handling Logic

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 5 * 1024 * 1024) { // 5MB limit
                setFileError('File size should not exceed 5MB');
                setFile(null);
            } else {
                setFileError(null);
                setFile(selectedFile);
            }
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        setFileError(null);
        // Reset the file input
        const fileInput = document.getElementById('file');
        if (fileInput) {
            fileInput.value = '';
        }
    };



    return (
        <div>
            {/*  <!--  Content --> */}
            <div className="cbrwb cqmv3 cn582">
                <div className="cau4d">
                    <div className="cbjuw cvcg6 c48gu ca7zr cfd1l cau4d cn582 c8og8 cmcfj">
                        {/* <!--  Site header --> */}
                        <header className="clnaa ce3tb c8og8">
                            <div className="chu3i cduop c1ymg c8og8 caqh0">
                                {/* <!--  Logo --> */}
                                <div class="ch3n4 ctzpc">
                                    {/* <!--  Logo --> */}
                                    <a class="cohq9 c6kou cu1zo" aria-label="Logo" onClick={() => navigate("/")}>
                                        <img src={logo} alt="Logo" />
                                    </a>

                                </div>
                                <a className="c1laq chgw8 cw2fq cu1zo" onClick={() => navigate("/")}><b>Project Mela</b></a>
                            </div>

                        </header>

                        <div className="ce3tb coh2n">
                            <div className="c6cl6">
                                <h1 className="cmz24 cuad0 cgmpd cmi0k">Post a Project</h1>
                                <div className="ca6yp">Find the best talent for your project and get your project done hassle free.</div>
                            </div>

                            {/* <!--  Form --> */}
                            <form className="cxjem" onSubmit={handleSubmit}>
                                <div className="cuiez c3217 c1c72">
                                    {/* <!--  Group #1 --> */}
                                    <div className="c0qn4">
                                        <div className="cznhr clt06 cqdqx cip9s"><span className="cw2fq">1.</span> About You
                                        </div>
                                        <div className="cbo0x">
                                            <div>
                                                <label className="c4vrg cf37z cohq9 ck6q6" htmlFor="name">Your Name <span className="corvs">*</span></label>
                                                <input id="name" className="cule2 cn582" type="text" required placeholder="Enter Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                                                {renderErrorMessage('name')}
                                            </div>


                                            <div>
                                                <label className="c4vrg cf37z cohq9 ck6q6" htmlFor="email">Contact Email <span className="corvs">*</span></label>
                                                <input id="email" className="cule2 cn582" type="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                                                {renderErrorMessage('email')}
                                            </div>


                                            <div>
                                                <label className="c4vrg cf37z cohq9 ck6q6" htmlFor="mobile">Mobile Number <span className="corvs">*</span></label>
                                                <PhoneInput
                                                    country='in'
                                                    countryCodeEditable={false}
                                                    value={mobile}
                                                    onChange={handlePhoneChange}
                                                    autoFormat={false}
                                                    searchClass='search-class'
                                                    inputStyle={{ width: '100%', maxWidth: '400px' }}
                                                    searchStyle={{ margin: '0', width: '97%', height: '30px' }}
                                                    enableSearch
                                                    disableSearchIcon
                                                    searchNotFound='Not found'
                                                    inputExtraProps={{
                                                        name: 'mobile',
                                                        required: true,
                                                        autoFocus: false,

                                                    }}
                                                />

                                                {phoneError !== '' && <p style={{ color: 'red' }}>{phoneError}</p>}

                                            </div>



                                        </div>
                                    </div>


                                    {/* <!--  Group #2 --> */}
                                    <div className="c0qn4">
                                        <div className="cznhr clt06 cqdqx cip9s"><span className="cw2fq">2.</span> About the Project
                                        </div>
                                        <div className="cbo0x">

                                            <div>
                                                <label className="c4vrg cf37z cohq9 ck6q6" htmlFor="projectTitle">Project Title <span className="corvs">*</span></label>
                                                <input id="projectTitle" className="cule2 cn582" type="text" required placeholder="Enter Project Title" value={projectTitle} onChange={(e) => setProjectTitle(e.target.value)} />
                                                {renderErrorMessage('projectTitle')}
                                            </div>

                                            <div>
                                                <label className="cznhr c4vrg cf37z cohq9 ck6q6" htmlFor="projectType">Project Type <span className="c0ix9">*</span></label>
                                                <select id="projectType" className="coiqx cf37z cn582 cm7rx" required value={projectType} onChange={(e) => setProjectType(e.target.value)}>
                                                    <option value="" disabled hidden>Choose a project type</option>
                                                    {projectRoleTypeData.map((type, index) => (
                                                        <option key={index}>{type}</option>
                                                    ))}
                                                </select>
                                            </div>


                                            <div>
                                                <label className="cznhr c4vrg cf37z cohq9 ck6q6" htmlFor="description">Project Description <span className="c0ix9">*</span></label>
                                                <textarea id="description" className="c8occ cf37z cn582 cm7rx" rows="4" required value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                                {renderErrorMessage('description')}
                                            </div>

                                            <div>
                                                <label className="cznhr c4vrg cf37z cohq9 ck6q6" htmlFor="requirements">Project Requirements <span className="c0ix9">*</span></label>
                                                <textarea id="requirements" className="c8occ cf37z cn582 cm7rx" rows="4" required value={requirements} onChange={(e) => setRequirements(e.target.value)}></textarea>
                                                {renderErrorMessage('requirements')}
                                            </div>

                                            <div>
                                                <label className="cznhr c4vrg cf37z cohq9 ck6q6" htmlFor="responsibilities">Key Responsibilities <span className="c0ix9">*</span></label>
                                                <textarea id="responsibilities" className="c8occ cf37z cn582 cm7rx" rows="4" required value={responsibilities} onChange={(e) => setResponsibilities(e.target.value)}></textarea>
                                                {renderErrorMessage('responsibilities')}
                                            </div>

                                            <div style={{ marginTop: '30px', marginBottom: '50px' }}>
                                                <label className="c4vrg cf37z cohq9 ck6q6" htmlFor="file">Other Project Related Documents <span
                                                    className="ca6yp">(optional)</span></label>
                                                <div className="cduop c8og8">
                                                    {/* <div className="ctzpc ch3n4">
                                                        <img className="czhnl c1g5q c0xth cqwhl caqh0 cb8dy"
                                                            src={uploadImage} alt="Upload">
                                                        </img>
                                                    </div> */}
                                                    <div>
                                                        <input id="file" type="file" onChange={handleFileChange}
                                                            className="cwsbp cjokd cs1ft cc88m cjtf9 cu1zo cvfxr ca6yp ct3l0 cyp4d comjk cmwpt cww2f c3sa8 clujg cf37z cn582 cohq9">
                                                        </input>
                                                    </div>
                                                </div>

                                                {fileError && <span className="text-danger">{fileError} <span><button
                                                    type="button"
                                                    onClick={handleRemoveFile}
                                                    className="remove-file-btn"
                                                    style={{
                                                        background: 'none',
                                                        border: 'none',
                                                        color: 'red',
                                                        cursor: 'pointer',
                                                        fontSize: '18px',
                                                        fontWeight: 'bold',
                                                        padding: '0 5px',
                                                    }}
                                                >
                                                    <b> × </b>
                                                </button></span></span>}
                                                {file && <div className="file-info" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                    <span className="text-success" style={{ marginRight: '10px' }}>File selected: {file.name}</span>
                                                    <button
                                                        type="button"
                                                        onClick={handleRemoveFile}
                                                        className="remove-file-btn"
                                                        style={{
                                                            background: 'none',
                                                            border: 'none',
                                                            color: 'red',
                                                            cursor: 'pointer',
                                                            fontSize: '18px',
                                                            fontWeight: 'bold',
                                                            padding: '0 5px',
                                                        }}
                                                    >
                                                        ×
                                                    </button>
                                                </div>}
                                            </div>

                                            <div>
                                                <label className="cznhr c4vrg cf37z cohq9 ck6q6" htmlFor="selectedTechStack">Skills Required <span className="c0ix9">*</span></label>
                                                <SkillsDropdown selectedSkills={selectedTechStack} setSelectedSkills={setSelectedTechStack} options={skillOptions} />
                                            </div>

                                            <div>
                                                <label className="c4vrg cf37z cohq9 ck6q6" htmlFor="budget">Project Budget (in Rs)</label>
                                                <input id="budget" className="cule2 cn582" type="number" disabled={noFixedBudget} value={budget} onChange={(e) => setBudget(e.target.value)} />
                                                <div className="ca6yp c9eyc c75bs clnyg">This information will NOT be disclosed with any developer.</div>
                                                <label className="c4vrg cf37z cohq9 ck6q6" style={{ marginTop: 20 }}>
                                                    <input type="checkbox" className="cicja" checked={noFixedBudget} onChange={(e) => {
                                                        setNoFixedBudget(e.target.checked);
                                                        if (e.target.checked) {
                                                            setBudget(''); // Reset budget value when checkbox is checked
                                                        }
                                                    }} />
                                                    <span className="cvhuf cf37z cm87k">No fixed Budget</span>
                                                </label>
                                            </div>



                                        </div>
                                    </div>

                                    {/* <!--  Group #3 --> */}
                                    <div className="c0qn4">
                                        <div className="cznhr clt06 cqdqx cip9s"><span className="cw2fq">3.</span> Select paid
                                            add-ons
                                        </div>
                                        <div className="cbo0x">
                                            <button type="button" className={`cm4vd clo09 cn582 cqwhl cwkz1 cp9oo ${stick ? 'cfxpd ciekg c0k6h' : 'czhnl'}`}
                                                onClick={(e) => { e.preventDefault(); setStick(!stick); }}>
                                                <div className="chu3i cduop c8og8">
                                                    <div>
                                                        <div className="cznhr c4vrg cf37z ck6q6"><b>Stick your post to stay on top</b></div>
                                                        <div className="ca6yp cf37z c75bs">2x more views</div>
                                                        <div className="cznhr c4vrg cf37z ck6q6"><b><span className="price-strike">+₹75</span> <span className="free-text">FREE</span></b></div>

                                                    </div>
                                                    <div className="czhnl c1g5q ctzpc cqwhl c75a8">
                                                        {!stick ? (
                                                            <svg className="cdzk3" width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21 15h-4v-4a1 1 0 0 0-2 0v4h-4a1 1 0 0 0 0 2h4v4a1 1 0 0 0 2 0v-4h4a1 1 0 0 0 0-2Z"></path>
                                                            </svg>
                                                        ) : (
                                                            <svg className="c96oa" width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="m20.28 12.28-6.292 6.294-2.293-2.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l7-7a1 1 0 0 0-1.414-1.414Z"></path>
                                                            </svg>
                                                        )}
                                                    </div>
                                                </div>
                                            </button>
                                            <button type="button" className={`cm4vd clo09 cn582 cqwhl cwkz1 cp9oo ${highlight ? 'cfxpd ciekg c0k6h' : 'czhnl'}`}
                                                onClick={(e) => { e.preventDefault(); setHighlight(!highlight); }}>
                                                <div className="chu3i cduop c8og8">
                                                    <div>
                                                        <div className="cznhr c4vrg cf37z ck6q6"><b>Notify Users about the Project Listing</b></div>
                                                        <div className="ca6yp cf37z c75bs">4x more views</div>
                                                        <div className="cznhr c4vrg cf37z ck6q6"><b><span className="price-strike">+₹125</span> <span className="free-text">FREE</span></b></div>

                                                    </div>
                                                    <div className="czhnl c1g5q ctzpc cqwhl c75a8">
                                                        {!highlight ? (
                                                            <svg className="cdzk3" width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21 15h-4v-4a1 1 0 0 0-2 0v4h-4a1 1 0 0 0 0 2h4v4a1 1 0 0 0 2 0v-4h4a1 1 0 0 0 0-2Z"></path>
                                                            </svg>
                                                        ) : (
                                                            <svg className="c96oa" width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="m20.28 12.28-6.292 6.294-2.293-2.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l7-7a1 1 0 0 0-1.414-1.414Z"></path>
                                                            </svg>
                                                        )}
                                                    </div>
                                                </div>
                                            </button>
                                        </div>

                                        <div className="cfait">
                                            <button
                                                className="czwz3 ce7qj cyky3 ctpla cn582 c0tyr"
                                            >
                                                <b>Post Project</b>
                                            </button>
                                        </div>

                                        {/* <button onClick={uploadDummyData} className="upload-dummy-data-btn">
                                            Upload Dummy Data
                                        </button>


                                        <button onClick={deleteTopSevenDocuments} className="delete-top-documents-btn">
                                            Delete Top Documents
                                        </button>


                                        <button onClick={updateIsStickPostonTopField} className="update-field-btn">
                                            Update 'isStickPostonTop' Field
                                        </button> */}

                                        {/* Check box which the user has to tick in order to post the project  */}

                                        <div className="c4vrg cf37z cohq9 ck6q6" style={{ marginTop: 20 }}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    className="cicja"
                                                    required
                                                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                                                />
                                                <span className="cvhuf cf37z cm87k">I agree to the Terms and Conditions and Privacy Policy</span>
                                            </label>
                                        </div>


                                        <div className="cs9kl">
                                            <div className="ca6yp c9eyc">By clicking this checkbox you agree to our <a className="csjdn"
                                                href="#0">Terms & Conditions</a> and <a className="csjdn" href="#0">Privacy
                                                    Policy</a>.</div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Right side */}
            <SideTestimonial testimonials={sideTestimonialListJobData} />

            <Modal
                show={showModal}
                onClose={() => {
                    setShowModal(false);
                    navigate('/');
                }}
                heading={modalHeading}
                body={modalMessage}
                primaryButtonText="OK"
                secondaryButtonText="Cancel"
                showButtons={false}
                onPrimaryClick={() => setShowModal(false)}
                onSecondaryClick={() => setShowModal(false)}
            />

            <CookiesModal
                showModal={!isCookiesAccepted}
                onAccept={handleAcceptCookies}
                onSecondaryButtonPressed={() => navigate('/privacy')}
            />

        </div>
    );
};

export default ListJob;

