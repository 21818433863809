// General Imports
import React, { useState, useEffect, useMemo } from 'react';

// Frontend Imports
import "../CSS/AdminDashboard.css";
import Loader from "../components/Loader/Loader";
import { toast } from 'react-toastify';
import TableRow from "../components/TableRow/TableRow";
import TableRowUser from "../components/TableRowUser/TableRowUser";
import StatsCard from "../components/StatsCard/StatsCard";
import SearchBar from "../components/SearchBar/SearchBar";
import Modal from '../components/General/Modal/Modal';
import ScrollableModal from '../components/General/ScrollableModal/ScrollableModal';
import { FaTimes } from 'react-icons/fa';
import AdminLogin from '../components/Admin/AdminLogin';
import AdminNav from '../components/Admin/AdminTabs';
import AdminHeader from '../components/Admin/AdminHeader';
import SkillsDropdown from '../components/SkillsDropdown/SkillsDropdown';

// Backend Imports
import { doc, collection, query, where, onSnapshot, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig/Firebase";
import { GoogleGenerativeAI } from "@google/generative-ai";
import { processAISearch, getSuggestedCandidates } from '../utils/aiLogic';

// Data Imports
import { getCardsData } from '../utils/otherFunctions';
import { dummyDataRows } from '../data/data';
import { skillOptions } from '../data/data';

// Utility Imports
import { getStatusClass } from '../utils/otherFunctions';
import { getTableHeaders } from '../utils/otherFunctions';
import { handleProjectLinkClick, handleClientLinkClick, updateProjectStatus, handleApplicationClientClick, handleApplicationProjectClick, handleApplicantProfileClick, handleApplicationApproval, handleApplicationDeletion } from '../utils/adminDashboardUtils';
import { formatShortDateIndiaStyle } from '../utils/dateFormatting';

const AdminDashboard = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [activeTab, setActiveTab] = useState('Applications');
    const [activeTabCounter, setActiveTabCounter] = useState(0);


    const [projectRequests, setProjectRequests] = useState([]);
    const [pendingProjects, setPendingProjects] = useState([]);
    const [ongoingProjects, setOngoingProjects] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [showScrollableModal, setShowScrollableModal] = useState(false);
    const [modalHeading, setModalHeading] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const [searchTerm, setSearchTerm] = useState('');
    const [userSearchTerm, setUserSearchTerm] = useState('');
    const [searchBy, setSearchBy] = useState('project');
    const [userSearchBy, setUserSearchBy] = useState('name');
    const [showFilterOption, setShowFilterOption] = useState(false);



    const [showSortTooltip, setShowSortTooltip] = useState(false);
    const [showUserSortTooltip, setShowUserSortTooltip] = useState(false);
    const [showFilterTooltip, setShowFilterTooltip] = useState(false);
    const [sortOption, setSortOption] = useState('');
    const [userSortOption, setUserSortOption] = useState('');
    const [filterOption, setFilterOption] = useState('');
    const [applications, setApplications] = useState([]);
    const [users, setUsers] = useState([]);


    const [selectedSkills, setSelectedSkills] = useState([]);
    const [selectedGender, setSelectedGender] = useState('');
    const [minXPFilter, setMinXPFilter] = useState('');
    const [maxXPFilter, setMaxXPFilter] = useState('');
    const [locationFilter, setLocationFilter] = useState([]);
    const [educationFilter, setEducationFilter] = useState([]);




    const [isAISearching, setIsAISearching] = useState(false);
    const [isAIAnalyzing, setIsAIAnalyzing] = useState(false);
    const [aiSearchQuery, setAiSearchQuery] = useState('');
    const [isAIFilterApplied, setIsAIFilterApplied] = useState(false);


    const [showSuggestionModal, setShowSuggestionModal] = useState(false);
    const [isModalMinimized, setIsModalMinimized] = useState(false);
    const [projectIdForSuggestion, setProjectIdForSuggestion] = useState('');
    const [suggestedCandidates, setSuggestedCandidates] = useState([]);
    const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);




    // Import the Gemini API Key properly from the dot env file and log it every time the page is loaded
    const geminiApiKey = process.env.REACT_APP_GEMINI_API_KEY;

    // Initialize Gemini API
    const genAI = new GoogleGenerativeAI(geminiApiKey);



    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setShowFilterOption(tab === 'Ongoing' || tab === 'Applications');

        clearSort();
        clearFilter();
        setSearchTerm('');

        setActiveTabCounter(prevCount => prevCount + 1);
    };



    // Handle sorting logic
    const handleSort = (sortBy) => {
        setSortOption(sortBy);
        setShowSortTooltip(false);
    };

    const handleUserSort = (sortBy) => {
        setUserSortOption(sortBy);
        setShowUserSortTooltip(false);
    };


    // Handle filtering logic
    const handleFilter = (filterBy) => {
        setFilterOption(filterBy);
        setShowFilterTooltip(false);
    };


    // Clear sorting
    const clearSort = () => {
        setSortOption('');
        setShowSortTooltip(false);
    };

    // Clear filtering
    const clearFilter = () => {
        setFilterOption('');
        setShowFilterTooltip(false);

    };

    const clearAIFilters = () => {
        setSelectedGender('');
        setSelectedSkills([]);
        setMinXPFilter('');
        setMaxXPFilter('');
        setLocationFilter([]);
        setEducationFilter([]);
        setUserSearchTerm('');
        setAiSearchQuery('');
        setIsAIFilterApplied(false);
    };




    useEffect(() => {
        if (!isLoggedIn) return;

        // Applications counter applicationsUnsubscribe only when atleast once the tab is changed so that the application table loads as soon as the page is loaded
        let applicationsUnsubscribe;
        // Running the 
        if (activeTabCounter > 0) {
            applicationsUnsubscribe = onSnapshot(
                query(
                    collection(db, "Applications"),
                    where("isApproved", "==", false),
                    where("isDeleted", "==", false)
                ),
                (snapshot) => setApplications(snapshot.docs)
            );
        }

        // Project Requests counter
        const requestsUnsubscribe = onSnapshot(
            query(
                collection(db, "Projects"),
                where("status", "==", "Unverified")
            ),
            (snapshot) => setProjectRequests(snapshot.docs)
        );

        // Pending Projects counter
        const pendingUnsubscribe = onSnapshot(
            query(
                collection(db, "Projects"),
                where("status", "==", "Pending")
            ),
            (snapshot) => setPendingProjects(snapshot.docs)
        );

        // Ongoing Projects counter
        const ongoingUnsubscribe = onSnapshot(
            query(
                collection(db, "Projects"),
                where("status", "in", ["Ongoing", "Finished"])
            ),
            (snapshot) => setOngoingProjects(snapshot.docs)
        );

        return () => {
            if (applicationsUnsubscribe) applicationsUnsubscribe();
            requestsUnsubscribe();
            pendingUnsubscribe();
            ongoingUnsubscribe();
        };
    }, [isLoggedIn, activeTabCounter]);


    const cardsData = useMemo(() => {
        return getCardsData(
            applications.length,
            projectRequests.length,
            pendingProjects.length,
            ongoingProjects.length
        );
    }, [applications, projectRequests, pendingProjects, ongoingProjects]);



    //FETCH DATA

    useEffect(() => {
        if (activeTab === 'Applications') {
            // Set up the listener
            const unsubscribe = onSnapshot(
                query(
                    collection(db, "Applications"),
                    where("isApproved", "==", false),
                    where("isDeleted", "==", false)
                ),
                async (applicationsSnapshot) => {
                    const applicationsData = [];

                    // Loop through the snapshot documents
                    for (const appDoc of applicationsSnapshot.docs) {
                        const appData = appDoc.data();

                        // Fetch project data
                        const projectDoc = await getDoc(doc(db, "Projects", appData.projectID));
                        const projectData = projectDoc.data();

                        // Fetch user data
                        const userDoc = await getDoc(doc(db, "Users", appData.ApplicantEmail));
                        const userData = userDoc.data();

                        applicationsData.push({
                            id: appDoc.id,
                            projectName: appData.projectName,
                            clientName: projectData?.name || 'N/A',
                            applicantFullName: [userData?.fullName || ''],
                            date: appData.submittedOn,
                            applicantSkills: userData?.Skills || [],
                            applicantPoints: userData?.XP || 0,
                            status: projectData?.status || 'N/A'
                        });
                    }

                    // Update state with the new data
                    setApplications(applicationsData);
                }
            );

            // Cleanup the listener on unmount or if activeTab changes
            return () => unsubscribe();
        }
    }, [activeTab]);


    useEffect(() => {
        // Define the query to filter projects based on the active tab
        let q;
        switch (activeTab) {
            case "Project Requests":
                q = query(collection(db, "Projects"), where("status", "==", "Unverified"));
                break;
            case "Pending":
                q = query(collection(db, "Projects"), where("status", "==", "Pending"));
                break;
            case "Ongoing":
                q = query(collection(db, "Projects"), where("status", "in", ["Ongoing", "Finished"]));
                break;
            default:
                return;
        }

        // Use onSnapshot to listen for real-time updates
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const projects = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));


            // Update the corresponding state based on the active tab
            switch (activeTab) {
                case "Project Requests":
                    setProjectRequests(projects);
                    break;
                case "Pending":
                    setPendingProjects(projects);
                    break;
                case "Ongoing":
                    setOngoingProjects(projects);
                    break;
                default:
                    return;
            }
        }, (error) => {
            console.error("Error fetching projects: ", error);
        });


        return () => unsubscribe();
    }, [activeTab]);


    // Fetching user Data for USER Tab


    useEffect(() => {
        if (activeTab === 'Users') {
            const unsubscribe = onSnapshot(collection(db, "Users"), (snapshot) => {
                const usersData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setUsers(usersData);
            });

            return () => unsubscribe();
        }
    }, [activeTab]);


    // FILTER USERS LOGIC

    const sortedFilteredUsers = useMemo(() => {
        let filtered = users.filter(user => {
            let searchField;
            switch (userSearchBy) {
                case 'name':
                    searchField = user.fullName;
                    break;
                case 'email':
                    searchField = user.Email;
                    break;
                case 'mobile':
                    searchField = user.Mobile;
                    break;
                default:
                    searchField = user.fullName;
            }

            let isSearchMatch = searchField && searchField.toLowerCase().includes(userSearchTerm.toLowerCase());


            let isManualSkillsMatch = true;
            if (selectedSkills.length > 0) {
                const userSkills = (user.Skills || []).map(skill => skill.toLowerCase());
                isManualSkillsMatch = selectedSkills.every(skill =>
                    userSkills.includes(skill.toLowerCase())
                );
            }

            let isEducationMatch = true;
            if (educationFilter.length > 0) {
                isEducationMatch = educationFilter.some(institute => {
                    const instituteLower = institute.toLowerCase();
                    const userInstitute = (user.EducationalInstitute || '').toLowerCase();
                    return userInstitute.includes(instituteLower) ||
                        instituteLower.includes(userInstitute);
                });
            }

            // New gender filter
            let isGenderMatch = true;
            if (selectedGender) {
                isGenderMatch = user.Gender === selectedGender;
            }

            // New XP range filter
            let isXPMatch = true;
            if (minXPFilter !== '') {
                isXPMatch = isXPMatch && user.XP >= parseInt(minXPFilter);
            }
            if (maxXPFilter !== '') {
                isXPMatch = isXPMatch && user.XP <= parseInt(maxXPFilter);
            }

            let isLocationMatch = true;
            if (locationFilter.length > 0) {
                isLocationMatch = locationFilter.some(location => {
                    const locationLower = location.toLowerCase();
                    return (
                        user.city?.toLowerCase().includes(locationLower) ||
                        user.state?.toLowerCase().includes(locationLower) ||
                        user.country?.toLowerCase().includes(locationLower)
                    );
                });
            }




            return isSearchMatch && isManualSkillsMatch && isGenderMatch && isXPMatch && isLocationMatch && isEducationMatch;
        });

        // Sort users based on the selected sort option
        if (userSortOption === 'high') {
            filtered.sort((a, b) => b.XP - a.XP); // XP High to Low
        } else if (userSortOption === 'low') {
            filtered.sort((a, b) => a.XP - b.XP); // XP Low to High
        }

        return filtered;
    }, [users, userSearchTerm, userSearchBy, userSortOption, selectedSkills,
        selectedGender, minXPFilter, maxXPFilter, locationFilter, educationFilter]);

    //SEARCH DATA AND SHOW NON DELETED PROJECTS  


    const filteredProjects = useMemo(() => {
        let projectsToFilter;

        switch (activeTab) {
            case 'Applications':
                projectsToFilter = applications;
                break;
            case 'Project Requests':
                projectsToFilter = projectRequests;
                break;
            case 'Pending':
                projectsToFilter = pendingProjects;
                break;
            case 'Ongoing':
                projectsToFilter = ongoingProjects;
                break;
            default:
                projectsToFilter = [];
        }

        // Filter out documents where isDeletedByAdmin is true
        projectsToFilter = projectsToFilter.filter(project => !project.isDeletedByAdmin);

        // Apply search filter
        projectsToFilter = projectsToFilter.filter(project => {
            const searchField = searchBy === 'project'
                ? (project.projectName || project.projectTitle)
                : (project.clientName || project.name);
            return searchField?.toLowerCase().includes(searchTerm.toLowerCase());
        });

        // Apply filter for status (Ongoing or Finished)
        if (activeTab === 'Ongoing' && filterOption) {
            projectsToFilter = projectsToFilter.filter(project => project.status === filterOption);
        }

        // Apply filter for status (Pending or Ongoing) for Applications tab
        if (activeTab === 'Applications' && filterOption) {
            projectsToFilter = projectsToFilter.filter(project => project.status === filterOption);
        }

        // Apply sorting for application tab
        if (activeTab === 'Applications' && sortOption) {
            projectsToFilter = projectsToFilter.sort((a, b) => {
                if (sortOption === 'recent') {
                    return b.date - a.date; // Recent to old
                } else if (sortOption === 'old') {
                    return a.date - b.date; // Old to recent
                } else if (sortOption === 'high') {
                    return b.applicantPoints - a.applicantPoints; // Points High to Low
                } else if (sortOption === 'low') {
                    return a.applicantPoints - b.applicantPoints; // Points Low to High
                }
                return 0;
            });
        }

        // Apply sorting for other tabs
        if (['Pending', 'Ongoing', 'Project Requests'].includes(activeTab) && sortOption) {
            projectsToFilter = projectsToFilter.sort((a, b) => {
                if (sortOption === 'recent') {
                    return b.createdAt.seconds - a.createdAt.seconds; // Recent to old
                } else if (sortOption === 'old') {
                    return a.createdAt.seconds - b.createdAt.seconds; // Old to recent
                } else if (sortOption === 'high') {
                    return b.budget - a.budget; // High to low price
                } else if (sortOption === 'low') {
                    return a.budget - b.budget; // Low to high price
                }
                return 0;
            });
        }

        return projectsToFilter;
    }, [activeTab, applications, projectRequests, pendingProjects, ongoingProjects, searchTerm, searchBy, sortOption, filterOption]);


    // AI Search Logic

    const handleAISearch = async () => {
        const attempts = 3; // Number of attempts
        for (let i = 0; i < attempts; i++) {
            try {
                await processAISearch(
                    aiSearchQuery,
                    setIsAISearching,
                    setIsAIFilterApplied,
                    setSelectedGender,
                    setSelectedSkills,
                    setMinXPFilter,
                    setMaxXPFilter,
                    setLocationFilter,
                    setEducationFilter,
                    handleTabChange,
                    setShowModal
                );
                return; // Exit if successful
            } catch (error) {
                if (i === attempts - 1) { // If last attempt, show error
                    setModalHeading('Error');
                    setModalMessage('Failed to process AI search. Please try again.');
                    setShowModal(true);
                }
            }
        }
    };

    const handleGetSuggestions = async () => {
        if (!projectIdForSuggestion) return;

        try {
            setIsFetchingSuggestions(true);
            const candidates = await getSuggestedCandidates(projectIdForSuggestion);
            setSuggestedCandidates(candidates);
            setShowSuggestionModal(true);
            setShowModal(false);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            toast.error('Invalid Project ID');
        } finally {
            setIsFetchingSuggestions(false);

        }
    };


    const analyzeCandidates = async () => {
        try {
            setIsAIAnalyzing(true);
            // Get project details
            const projectDoc = await getDoc(doc(db, "Projects", projectIdForSuggestion));
            const projectData = projectDoc.data();

            const updatedCandidates = await Promise.all(suggestedCandidates.map(async (candidate) => {
                console.log("Candidate Details:", candidate); // Log candidate details
                // Prepare data for AI analysis
                const aiInput = {
                    projectDetails: {
                        description: projectData.description,
                        requirements: projectData.requirements,
                        responsibilities: projectData.responsibilities
                    },
                    candidateDetails: {
                        email: candidate.Email,
                        aboutMe: candidate.AboutMe || '',
                        experience: candidate.MyExperience || '',
                        pastProjects: candidate.PastProjects || '',
                        skills: candidate.Skills || [],
                    }
                };

                // Generate AI analysis using Gemini
                const model = genAI.getGenerativeModel({ model: "gemini-pro" });
                const prompt = `You are an AI recruitment assistant. Analyze the candidate's suitability for the project based on the following information. Provide your response in strict JSON format with exactly two fields: "analysis" (string) and "confidenceScore" (number between 0-1000) Be very meticulous and precise in giving the AI confidence score and it should reflect the difference between one candidate with another be as precise with the score as possible.

                Project Details:
                ${JSON.stringify(aiInput.projectDetails, null, 2)}

                Candidate Details:
                ${JSON.stringify(aiInput.candidateDetails, null, 2)}


                Respond only with a JSON object in this exact format:
                {"analysis": "your detailed analysis here", "confidenceScore": number}`;

                const result = await model.generateContent(prompt);
                const responseText = result.response.text();

                // Clean up the response text to ensure valid JSON
                const cleanResponse = responseText.replace(/```json\n?|\n?```/g, '').trim();

                try {
                    const response = JSON.parse(cleanResponse);
                    return {
                        ...candidate,
                        aiAnalysis: response.analysis,
                        aiConfidence: response.confidenceScore
                    };
                } catch (parseError) {
                    console.error('Error parsing AI response:', parseError);
                    console.log('Raw AI response:', responseText);
                    return {
                        ...candidate,
                        aiAnalysis: "Error analyzing candidate",
                        aiConfidence: 0
                    };
                }
            }));
            const sortedCandidates = updatedCandidates.sort((a, b) => b.aiConfidence - a.aiConfidence);
            setSuggestedCandidates(updatedCandidates);
        } catch (error) {
            console.error('Error in AI analysis:', error);
            toast.error('Failed to analyze candidates');
        } finally {
            setIsAIAnalyzing(false);
        }
    };




    return (

        <>

            {!isLoggedIn ? (
                <AdminLogin onLoginSuccess={() => setIsLoggedIn(true)} />
            ) : (

                <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                    {/* <!-- Main content  --> */}
                    <div class="h-screen flex-grow-1 overflow-y-lg-auto">
                        {/* <!-- Header  --> */}
                        <header class="bg-surface-primary border-bottom pt-6">
                            <div class="container-fluid">
                                <div class="mb-npx">
                                    <AdminHeader />
                                    {/* <!-- Nav  --> */}
                                    <AdminNav activeTab={activeTab} handleTabChange={handleTabChange} />
                                </div>
                            </div>
                        </header>
                        {/* <!-- Main  --> */}
                        <main class="py-6 bg-surface-secondary">
                            <div class="container-fluid">

                                {/* <!-- Card stats  --> */}

                                <div className="row g-6 mb-6">
                                    {cardsData.map((card, index) => (
                                        <StatsCard key={index} {...card} />
                                    ))}
                                </div>

                                {/* Search Bar */}

                                <div className="mb-4">


                                    {activeTab !== 'Users' && (

                                        <div className='searchbar-wrapper-admin d-flex align-items-start'>
                                            <SearchBar
                                                placeholder={`Search by ${searchBy}...`}
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                showFilterOptions={showFilterOption}
                                                onClickSortOptions={() => setShowSortTooltip(!showSortTooltip)}
                                                onClickFilterOptions={() => setShowFilterTooltip(!showFilterTooltip)}
                                                showFilterDot={!!filterOption}
                                                showSortDot={!!sortOption}
                                            />

                                            {/* Sort Tooltip */}
                                            {showSortTooltip && (
                                                activeTab === 'Applications' ? (
                                                    <div className="tooltip-box" style={{ marginTop: '10px' }}>
                                                        <div className="tooltip-header">
                                                            <span>Sort Options</span>
                                                            <button className="close-btn" onClick={() => setShowSortTooltip(false)}> <FaTimes size={20} /></button>
                                                        </div>
                                                        <div className="tooltip-options">
                                                            <button onClick={() => handleSort('recent')}>Recent to Old</button>
                                                            <button onClick={() => handleSort('old')}>Old to Recent</button>
                                                            <button onClick={() => handleSort('high')}>Points High to Low</button>
                                                            <button onClick={() => handleSort('low')}>Points Low to High</button>
                                                            <button className="clear-btn" onClick={() => clearSort()}><b>Clear</b></button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="tooltip-box" style={{ marginTop: '10px' }}>
                                                        <div className="tooltip-header">
                                                            <span>Sort Options</span>
                                                            <button className="close-btn" onClick={() => setShowSortTooltip(false)}> <FaTimes size={20} /></button>
                                                        </div>
                                                        <div className="tooltip-options">
                                                            <button onClick={() => handleSort('recent')}>Recent to Old</button>
                                                            <button onClick={() => handleSort('old')}>Old to Recent</button>
                                                            <button onClick={() => handleSort('high')}>Price High to Low</button>
                                                            <button onClick={() => handleSort('low')}>Price Low to High</button>
                                                            <button className="clear-btn" onClick={() => clearSort()}><b>Clear</b></button>
                                                        </div>
                                                    </div>
                                                )
                                            )}

                                            {/* Filter Tooltip */}
                                            {showFilterTooltip && (
                                                <div className="tooltip-box" style={{ marginTop: '10px' }}>
                                                    <div className="tooltip-header">
                                                        <span>Filter Options</span>
                                                        <button className="close-btn" onClick={() => setShowFilterTooltip(false)}> <FaTimes size={20} /></button>
                                                    </div>
                                                    <div className="tooltip-options">
                                                        {activeTab === 'Applications' ? (
                                                            <>
                                                                <button onClick={() => handleFilter('Pending')}>Pending</button>
                                                                <button onClick={() => handleFilter('Ongoing')}>Ongoing</button>
                                                                <button className="clear-btn" onClick={() => clearFilter()}> <b>Clear</b></button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <button onClick={() => handleFilter('Ongoing')}>Ongoing</button>
                                                                <button onClick={() => handleFilter('Finished')}>Finished</button>
                                                                <button className="clear-btn" onClick={() => clearFilter()}> <b>Clear</b></button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}


                                        </div>

                                    )}

                                    {activeTab === 'Users' && (
                                        <>
                                            {/* Search Bar for Users */}
                                            <div className='searchbar-wrapper-admin d-flex align-items-start'>
                                                <SearchBar
                                                    placeholder={`Search by ${userSearchBy}...`}
                                                    value={userSearchTerm}
                                                    onChange={(e) => setUserSearchTerm(e.target.value)}
                                                    className="mb-4"
                                                    onClickSortOptions={() => setShowUserSortTooltip(!showUserSortTooltip)}
                                                    showSortDot={!!userSortOption}    // Show sort tooltip
                                                />

                                                {showUserSortTooltip && (
                                                    <div className="tooltip-box" style={{ marginTop: '10px' }}>
                                                        <div className="tooltip-header">
                                                            <span>Sort Options</span>
                                                            <button className="close-btn" onClick={() => setShowUserSortTooltip(false)}> <FaTimes size={20} /></button>
                                                        </div>
                                                        <div className="tooltip-options">
                                                            <button onClick={() => handleUserSort('low')}>XP Low to High</button>
                                                            <button onClick={() => handleUserSort('high')}>XP High to Low</button>
                                                            <button className="clear-btn" onClick={() => setUserSortOption('')}><b>Clear</b></button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            {/* Radio Buttons for Search Criteria */}
                                            <div className="mb-4">
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="userSearchBy"
                                                        id="userSearchByName"
                                                        value="name"
                                                        checked={userSearchBy === 'name'}
                                                        onChange={() => setUserSearchBy('name')}
                                                    />
                                                    <label className="form-check-label" htmlFor="userSearchByName">
                                                        Name
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="userSearchBy"
                                                        id="userSearchByEmail"
                                                        value="email"
                                                        checked={userSearchBy === 'email'}
                                                        onChange={() => setUserSearchBy('email')}
                                                    />
                                                    <label className="form-check-label" htmlFor="userSearchByEmail">
                                                        Email
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="userSearchBy"
                                                        id="userSearchByMobile"
                                                        value="mobile"
                                                        checked={userSearchBy === 'mobile'}
                                                        onChange={() => setUserSearchBy('mobile')}
                                                    />
                                                    <label className="form-check-label" htmlFor="userSearchByMobile">
                                                        Mobile
                                                    </label>
                                                </div>
                                            </div>


                                            <div className="row mb-4">
                                                <div className="col-md-2">
                                                    <label className="form-label"><b>Gender</b></label>
                                                    <select
                                                        className="form-select"
                                                        value={selectedGender}
                                                        onChange={(e) => setSelectedGender(e.target.value)}
                                                    >
                                                        <option value="">All</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Non Binary">Non Binary</option>
                                                    </select>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="form-label"><b>XP Range</b></label>
                                                    <div className="d-flex gap-3">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Min"
                                                            value={minXPFilter}
                                                            onChange={(e) => setMinXPFilter(e.target.value)}
                                                        />
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Max"
                                                            value={maxXPFilter}
                                                            onChange={(e) => setMaxXPFilter(e.target.value)}
                                                        />
                                                        {(minXPFilter || maxXPFilter) && (
                                                            <button
                                                                className="btn btn-secondary"
                                                                onClick={() => {
                                                                    setMinXPFilter('');
                                                                    setMaxXPFilter('');
                                                                }}
                                                            >
                                                                <i className="bi bi-trash"></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>


                                                <div className="col-md-3">
                                                    <label className="form-label"><b>Location</b></label>
                                                    <div className="d-flex gap-2">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Type location and press Enter"
                                                            // Remove the value binding to allow free typing
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' && e.target.value.trim()) {
                                                                    e.preventDefault();
                                                                    // Add the new location to the existing array
                                                                    const newLocation = e.target.value.trim();
                                                                    if (!locationFilter.includes(newLocation)) {
                                                                        setLocationFilter([...locationFilter, newLocation]);
                                                                    }
                                                                    // Clear the input after adding
                                                                    e.target.value = '';
                                                                }
                                                            }}
                                                        />
                                                        {locationFilter.length > 0 && (
                                                            <button
                                                                className="btn btn-secondary"
                                                                onClick={() => setLocationFilter([])}
                                                            >
                                                                <i className="bi bi-trash"></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                    {/* Show selected locations as tags */}
                                                    <div className="mt-2 d-flex flex-wrap gap-2">
                                                        {locationFilter.map((location, index) => (
                                                            <span
                                                                key={index}
                                                                className="badge bg-primary d-flex align-items-center gap-2"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    setLocationFilter(locationFilter.filter((_, i) => i !== index));
                                                                }}
                                                            >
                                                                {location}
                                                                <i className="bi bi-x"></i>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>


                                                <div className="col-md-3">
                                                    <label className="form-label"><b>Educational Institute</b></label>
                                                    <div className="d-flex gap-2">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Type institute and press Enter"
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' && e.target.value.trim()) {
                                                                    e.preventDefault();
                                                                    const newInstitute = e.target.value.trim();
                                                                    if (!educationFilter.includes(newInstitute)) {
                                                                        setEducationFilter([...educationFilter, newInstitute]);
                                                                    }
                                                                    e.target.value = '';
                                                                }
                                                            }}
                                                        />
                                                        {educationFilter.length > 0 && (
                                                            <button
                                                                className="btn btn-secondary"
                                                                onClick={() => setEducationFilter([])}
                                                            >
                                                                <i className="bi bi-trash"></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="mt-2 d-flex flex-wrap gap-2">
                                                        {educationFilter.map((institute, index) => (
                                                            <span
                                                                key={index}
                                                                className="badge bg-primary d-flex align-items-center gap-2"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    setEducationFilter(educationFilter.filter((_, i) => i !== index));
                                                                }}
                                                            >
                                                                {institute}
                                                                <i className="bi bi-x"></i>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="mb-4">
                                                <label className="form-label" style={{ marginBottom: '10px' }}><b>Skill</b></label>
                                                <div className="d-flex align-items-center">
                                                    <label className="me-3"> &nbsp; </label>
                                                    <div className="d-flex align-items-start">
                                                        <SkillsDropdown
                                                            selectedSkills={selectedSkills}
                                                            setSelectedSkills={setSelectedSkills}
                                                            options={skillOptions}
                                                        />
                                                        {selectedSkills.length > 0 && (
                                                            <button
                                                                className="btn btn-secondary ms-3 mt-1"
                                                                onClick={() => setSelectedSkills([])}
                                                            >
                                                                <i className="bi bi-trash"></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    )}

                                    {activeTab !== 'Users' && (
                                        <>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="searchBy"
                                                    id="searchByProject"
                                                    value="project"
                                                    checked={searchBy === 'project'}
                                                    onChange={() => setSearchBy('project')}
                                                />
                                                <label className="form-check-label" htmlFor="searchByProject">
                                                    Project
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="searchBy"
                                                    id="searchByClient"
                                                    value="client"
                                                    checked={searchBy === 'client'}
                                                    onChange={() => setSearchBy('client')}
                                                />
                                                <label className="form-check-label" htmlFor="searchByClient">
                                                    Client
                                                </label>
                                            </div>

                                            <div style={{ marginBottom: '15px' }}> </div>

                                        </>
                                    )}

                                    <div className="d-flex">
                                        {activeTab === 'Users' && (
                                            <>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        setModalHeading('AI Search');
                                                        setModalMessage('Describe the candidate you are looking for:');
                                                        setShowModal(true);
                                                    }}
                                                >
                                                    AI Search
                                                </button>

                                                <div style={{ marginLeft: '5px', marginRight: '5px' }}> </div>
                                            </>
                                        )}

                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                setModalHeading('AI Suggestion');
                                                setModalMessage('Enter Project ID:');
                                                setShowModal(true);
                                            }}
                                        >
                                            AI Suggestion
                                        </button>

                                        <div style={{ marginLeft: '5px', marginRight: '5px' }}> </div>

                                        {isAIFilterApplied && (
                                            <button
                                                className="btn btn-secondary"
                                                onClick={() => {
                                                    clearAIFilters();

                                                }}
                                            >
                                                Clear All Filters
                                            </button>
                                        )}

                                    </div>

                                </div>


                                {/* Main Table Area */}


                                <div className="card shadow border-0 mb-7">
                                    <div className="card-header">
                                        <h5 className="mb-0">{activeTab}</h5>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-nowrap">

                                            <thead className="thead-light">
                                                {getTableHeaders(activeTab)}
                                            </thead>

                                            <tbody>
                                                {activeTab === 'Users' && sortedFilteredUsers.map(user => (
                                                    <TableRowUser key={user.id} user={user} />
                                                ))}

                                                {activeTab === 'Applications' && filteredProjects.map(app => (
                                                    <TableRow
                                                        key={app.id || ''}
                                                        projectId={app.id || ''}
                                                        projectName={app.projectName || ''}
                                                        applicantNames={app.applicantFullName || []}
                                                        clientName={app.clientName || ''}
                                                        date={app.date ? formatShortDateIndiaStyle(app.date) : ''}
                                                        skills={app.applicantSkills || []}
                                                        points={app.applicantPoints || 0}
                                                        status={app.status || 'Unknown'}
                                                        statusClass={getStatusClass(app.status)}
                                                        activeTab={activeTab}
                                                        onMainButtonClick={(e) => handleApplicationApproval(app.id, e)}
                                                        onProjectClick={() => handleApplicationProjectClick(app.id, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        onClientClick={() => handleApplicationClientClick(app.id, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                    />
                                                ))}
                                                {activeTab === 'Pending' && filteredProjects.map(project => (
                                                    <TableRow
                                                        key={project.id}
                                                        projectId={project.id}
                                                        projectName={project.projectTitle || ''}
                                                        onProjectClick={() => handleProjectLinkClick(project, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        clientName={project.name || ''}
                                                        onClientClick={() => handleClientLinkClick(project, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        date={project.createdAt ? formatShortDateIndiaStyle(project.createdAt) : ''}
                                                        budget={project.budget || 0}
                                                        skills={project.techStack || []}
                                                        notifyUser={project.isNotifyPostonEmail || false}
                                                        points={project.points || 0}
                                                        minXP={project.minXP || 0}
                                                        status={project.status || 'Unknown'}
                                                        statusClass={getStatusClass(project.status)}
                                                        activeTab={activeTab}
                                                        moderatorNames={project.moderators || []}
                                                        applicantNames={project.selectedApplicants || []}
                                                        removedApplicantNames={project.removedApplicants || []}
                                                        removedModerators={project.removedModerators || []}
                                                        onMainButtonClick={() => updateProjectStatus(project.id, "Ongoing")}
                                                    />


                                                ))}
                                                {activeTab === 'Ongoing' && filteredProjects.map(project => (
                                                    <TableRow
                                                        key={project.id || ''}
                                                        projectId={project.id || ''}
                                                        projectName={project.projectTitle || ''}
                                                        onProjectClick={() => handleProjectLinkClick(project, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        clientName={project.name || ''}
                                                        onClientClick={() => handleClientLinkClick(project, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        date={project.createdAt ? formatShortDateIndiaStyle(project.createdAt) : ''}
                                                        budget={project.budget || 0}
                                                        skills={project.techStack || []}
                                                        notifyUser={project.isNotifyPostonEmail || false}
                                                        points={project.points || 0}
                                                        minXP={project.minXP || 0}
                                                        status={project.status || 'Unknown'}
                                                        statusClass={getStatusClass(project.status)}
                                                        activeTab={activeTab}
                                                        moderatorNames={project.moderators || []}
                                                        applicantNames={project.selectedApplicants || []}
                                                        removedApplicantNames={project.removedApplicants || []}
                                                        removedModerators={project.removedModerators || []}
                                                        onMainButtonClick={() => updateProjectStatus(project.id, "Finished")}
                                                    />
                                                ))}
                                                {activeTab === 'Project Requests' && filteredProjects.map(project => (
                                                    <TableRow
                                                        key={project.id || ''}
                                                        projectId={project.id || ''}
                                                        projectName={project.projectTitle || ''}
                                                        onProjectClick={() => handleProjectLinkClick(project, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        clientName={project.name || ''}
                                                        onClientClick={() => handleClientLinkClick(project, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        date={project.createdAt ? formatShortDateIndiaStyle(project.createdAt) : ''}
                                                        budget={project.budget || 0}
                                                        skills={project.techStack || []}
                                                        notifyUser={project.isNotifyPostonEmail || false}
                                                        points={project.points || 0}
                                                        minXP={project.minXP || 0}
                                                        status={project.status || 'Unknown'}
                                                        statusClass={getStatusClass(project.status)}
                                                        activeTab={activeTab}
                                                        onMainButtonClick={() => updateProjectStatus(project.id, "Pending")}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>


                                        {isModalMinimized && (
                                            <div
                                                className='minimized-modal-button'

                                                onClick={() => setIsModalMinimized(false)}
                                                title="Show Suggested Candidates"
                                            >
                                                <i className="bi bi-box-arrow-up-left"></i>
                                            </div>
                                        )}


                                        <Modal
                                            show={showModal}
                                            onClose={() => {
                                                setShowModal(false);
                                            }}
                                            heading={modalHeading}
                                            body={modalMessage}
                                            primaryButtonText="OK"
                                            secondaryButtonText="Cancel"
                                            showButtons={false}
                                            onPrimaryClick={() => setShowModal(false)}
                                            onSecondaryClick={() => setShowModal(false)}
                                        />


                                        <ScrollableModal
                                            show={showScrollableModal}
                                            onClose={() => {
                                                setShowScrollableModal(false);
                                            }}
                                            heading={modalHeading}
                                            body={modalMessage}
                                            primaryButtonText="OK"
                                            secondaryButtonText="Cancel"
                                            showButtons={false}
                                            onPrimaryClick={() => { }}
                                            onSecondaryClick={() => { }}
                                        />

                                        {/* AI Modal  */}


                                        <Modal
                                            show={showModal}
                                            onClose={() => {
                                                setShowModal(false);
                                                setAiSearchQuery('');
                                            }}
                                            heading={modalHeading}
                                            body={modalMessage}
                                            primaryButtonText={isAISearching ? "Searching..." : "Search"}
                                            secondaryButtonText="Cancel"
                                            showButtons={true}
                                            showInputField={true}
                                            inputPlaceholder="Example: Female candidate who knows React and Firebase and stays in Mumbai"
                                            inputType="text"
                                            onInputChange={(e) => setAiSearchQuery(e.target.value)}
                                            onPrimaryClick={handleAISearch}
                                            onSecondaryClick={() => {
                                                setShowModal(false);
                                                setAiSearchQuery('');
                                            }}
                                        />


                                        {/* Suggestion Modal Box */}

                                        <Modal
                                            show={showModal && modalHeading === 'AI Suggestion'}
                                            onClose={() => {
                                                setShowModal(false);
                                                setProjectIdForSuggestion('');
                                            }}
                                            heading={modalHeading}
                                            body={modalMessage}
                                            primaryButtonText={isFetchingSuggestions ? "Fetching..." : "Search"}
                                            secondaryButtonText="Cancel"
                                            showButtons={true}
                                            showInputField={true}
                                            inputPlaceholder="Enter Project ID"
                                            inputType="text"
                                            onInputChange={(e) => setProjectIdForSuggestion(e.target.value)}
                                            onPrimaryClick={handleGetSuggestions}
                                            onSecondaryClick={() => {
                                                setShowModal(false);
                                                setProjectIdForSuggestion('');
                                            }}
                                        />

                                        <ScrollableModal
                                            show={showSuggestionModal && !isModalMinimized}
                                            onClose={() => {
                                                setShowSuggestionModal(false);
                                                setIsModalMinimized(false);
                                                setSuggestedCandidates([]);
                                            }}
                                            heading="Suggested Candidates"
                                            body={
                                                <div>
                                                    {suggestedCandidates.map((candidate, index) => (
                                                        <div key={candidate.id} className="card mb-3">
                                                            <div className="card-body">
                                                                <h5 className="card-title">{index + 1}. {candidate.fullName}</h5>
                                                                <p className="card-text">
                                                                    <strong>Matching Skills:</strong> {candidate.matchScore} skills<br />
                                                                    <strong>Skills:</strong> {candidate.Skills?.join(', ')}<br />
                                                                    <strong>Email:</strong> {candidate.Email}<br />
                                                                    <strong>XP:</strong> {candidate.XP || 0}<br /><br />
                                                                    <strong>About Me:</strong> {candidate.AboutMe || 'N/A'}<br /><br />
                                                                    <strong>Experience:</strong> {candidate.MyExperience || 'N/A'}<br /><br />
                                                                    <strong>Past Project:</strong> {candidate.PastProjects || 'N/A'}<br /><br />
                                                                    {candidate.aiAnalysis && (
                                                                        <div style={{ backgroundColor: 'lightyellow', borderRadius: '10px' }}>
                                                                            <br />
                                                                            <strong>AI Analysis:</strong> {candidate.aiAnalysis}<br /><br />
                                                                            <strong>AI Confidence:</strong> {candidate.aiConfidence}<br /><br />
                                                                        </div>
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {suggestedCandidates.length === 0 && (
                                                        <p>No matching candidates found.</p>
                                                    )}
                                                </div>
                                            }
                                            primaryButtonText={isAIAnalyzing ? "Analyzing..." : "AI Analysis"}
                                            secondaryButtonText="Minimize"
                                            showButtons={true}
                                            onPrimaryButtonClicked={analyzeCandidates}
                                            onSecondaryButtonClicked={() => setIsModalMinimized(true)}
                                        />

                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

            )}



        </>



    );
};

export default AdminDashboard;