import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import logo from '../images/logo2.svg';
import Modal from '../components/General/Modal/Modal';
import SideTestimonial from '../components/SideTestimonials/SideTestimonials';
import { sideTestimonialSigninData } from '../data/data';

// Backend Imports
import { auth, db } from '../firebaseConfig/Firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { handleGoogleSignIn } from '../Backend/Auth/GoogleSignIn';
import { createUserDocument } from '../Backend/Auth/CreateUserDocument';


const Signin = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [modalHeading, setModalHeading] = useState('');



    useEffect(() => {
        // Set the persistence to LOCAL when the component mounts
        setPersistence(auth, browserLocalPersistence)
            .catch((error) => {
                // Handle any errors in setting persistence
                console.error("Error setting persistence:", error);
            });
    }, []);


    const handleSignInClick = async (e) => {
        e.preventDefault();
        if (isSignUp) {
            if (password !== confirmPassword) {
                setPasswordsMatch(false);
                return;
            }
            if (!isPasswordValid(password)) {
                setModalHeading("Invalid Password");
                setModalMessage("Password must be at least 6 characters long, contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.");
                setShowModal(true);
                return;
            }



            if (!/^[a-zA-Z]+$/.test(firstName) || !/^[a-zA-Z]+$/.test(lastName)) {
                setModalHeading("Invalid Name");
                setModalMessage("First Name and Last Name cannot have spaces or special characters.");
                setShowModal(true);
                return false;
            }

            try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                await sendEmailVerification(userCredential.user);
                await createUserDocument(userCredential.user, { firstName, lastName });
                setModalHeading("Verification Email Sent ✅");
                setModalMessage('Please check your inbox and verify your email to sign in.');
                setShowModal(true);
                setIsSignUp(false);  // Switch back to sign in mode
            } catch (error) {
                if (error.code === 'auth/email-already-in-use') {
                    setModalHeading("Email Already in Use");
                    setModalMessage("Sign in through this email or create a new account with a different email.");
                } else {
                    setModalHeading("Sign Up Failed");
                    setModalMessage(`${error.message}`);
                }
                setShowModal(true);
            }
        } else {
            try {
                const userCredential = await signInWithEmailAndPassword(auth, email, password);
                if (userCredential.user.emailVerified) {
                    navigate('/profile');
                } else {
                    setModalHeading("Email Not Verified");
                    setModalMessage('Please verify your email before signing in.');
                    setShowModal(true);
                }
            } catch (error) {
                if (error.code === 'auth/invalid-credential') {
                    setModalHeading("Sign In Failed");
                    setModalMessage('Invalid Email and Password Combination');
                } else {
                    setModalHeading("Sign In Failed");
                    setModalMessage(`${error.message}`);
                }
                setShowModal(true);
            }
        }
    };

    const toggleSignUpMode = () => {
        setIsSignUp(!isSignUp);
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setPasswordsMatch(true);
        setFirstName('');
        setLastName('');
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (isSignUp) {
            setPasswordsMatch(e.target.value === confirmPassword);
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordsMatch(password === e.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const isPasswordValid = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
        return regex.test(password);
    };

    const handleGoogleSignInWrapper = async () => {
        try {
            const user = await handleGoogleSignIn();
            if (user) {
                await createUserDocument(user);
                navigate('/profile');
            }
        } catch (error) {
            console.error("Error during Google Sign In:", error);
            setModalHeading("Sign In Failed");
            setModalMessage("An error occurred during Google Sign In. Please try again.");
            setShowModal(true);
        }
    };

    return (
        <>

            <div>
                {/* Content */}
                <div className="cbrwb cqmv3 cn582">
                    <div className="cau4d">
                        <div className="cbjuw cvcg6 c48gu ca7zr cfd1l cau4d cn582 c8og8 cmcfj">
                            {/* Site header */}
                            <header className="clnaa ce3tb c8og8">
                                <div className="chu3i cduop c1ymg c8og8 caqh0">
                                    {/* Logo */}
                                    <div className="ch3n4 ctzpc">
                                        {/* <!--  Logo --> */}
                                        <a className="cohq9 c6kou" href="index.html" aria-label="Logo">
                                            <img src={logo} alt="Logo" />
                                        </a>
                                    </div>
                                    <span className="c1laq chgw8 cw2fq"><b>Project Mela</b></span>
                                </div>
                            </header>

                            <div className="ce3tb coh2n">
                                <div className="c6cl6">
                                    <h1 className="cmz24 cuad0 cgmpd cmi0k">{isSignUp ? 'Sign up on Project Mela' : 'Sign in to Project Mela!'}</h1>
                                    <div className="ca6yp">Join the ultimate project platform to boost your skills, earn money, and work on exciting tech projects with fellow programmers.</div>
                                </div>

                                {/* Form */}
                                <form onSubmit={handleSignInClick}>
                                    {isSignUp && (
                                        <div className="cbo0x">
                                            <div>
                                                <label className="c4vrg cf37z cohq9 ck6q6" htmlFor="firstName">First Name</label>
                                                <input id="firstName" className="cule2 cn582" type="text" required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                            </div>
                                            <div>
                                                <label className="c4vrg cf37z cohq9 ck6q6" htmlFor="lastName">Last Name</label>
                                                <input id="lastName" className="cule2 cn582" type="text" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                            </div>
                                        </div>
                                    )}

                                    <div className="cbo0x">
                                        <div>
                                            <label className="c4vrg cf37z cohq9 ck6q6" htmlFor="email">Email</label>
                                            <input id="email" className="cule2 cn582" type="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                    </div>
                                    {/* Password input with see and hide password toggle */}
                                    <div className="cbo0x">
                                        <div className="password-input-wrapper">
                                            <label className="c4vrg cf37z cohq9 ck6q6" htmlFor="password">
                                                Password
                                            </label>
                                            <div className="password-input-container">
                                                <input
                                                    id="password"
                                                    className="cule2 cn582 password-input"
                                                    type={showPassword ? 'text' : 'password'}
                                                    required
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                />
                                                <button
                                                    type="button"
                                                    className="password-toggle-button"
                                                    onClick={togglePasswordVisibility}
                                                >
                                                    {showPassword ? (
                                                        <FaEyeSlash className="password-icon" />
                                                    ) : (
                                                        <FaEye className="password-icon" />
                                                    )}
                                                </button>
                                            </div>
                                            {isSignUp && (
                                                <p className="password-hint">
                                                    Password must be at least 6 characters long, contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.
                                                </p>

                                            )}
                                        </div>
                                    </div>

                                    {/* Confirm Password  */}
                                    {isSignUp && (
                                        <div className="cbo0x">
                                            <div className="password-input-wrapper">
                                                <label className="c4vrg cf37z cohq9 ck6q6" htmlFor="confirmpassword">
                                                    Confirm Password
                                                </label>
                                                <div className="password-input-container">
                                                    <input
                                                        id="confirmpassword"
                                                        className="cule2 cn582 password-input"
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        required
                                                        value={confirmPassword}
                                                        onChange={handleConfirmPasswordChange}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="password-toggle-button"
                                                        onClick={toggleConfirmPasswordVisibility}
                                                    >
                                                        {showConfirmPassword ? (
                                                            <FaEyeSlash className="password-icon" />
                                                        ) : (
                                                            <FaEye className="password-icon" />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!passwordsMatch && password !== '' && confirmPassword !== '' && (
                                        <div className="error-message" style={{ color: 'red' }}>
                                            Passwords do not match
                                        </div>
                                    )}

                                    {!isSignUp && (
                                        <div style={{ marginTop: '20px' }}>
                                            <a onClick={() => navigate('/forgot-password')}>
                                                <span className="cw2fq cu1zo">
                                                    Forgot Password

                                                </span>
                                            </a>
                                        </div>
                                    )}

                                    <div style={{ marginTop: '10px' }}>
                                        <a onClick={toggleSignUpMode}>
                                            <span className="cw2fq cu1zo">
                                                {isSignUp ? "Already have an account? Sign in" : "Don't have an Account? "}
                                                {!isSignUp && <span style={{ textDecoration: 'underline' }}>Signup</span>}
                                            </span>
                                        </a>
                                    </div>

                                    <div className="cfait">
                                        <button type="submit" className="czwz3 ce7qj cyky3 ctpla cn582 c6kou c0tyr">
                                            <b>{isSignUp ? 'Sign Up' : 'Sign In'}</b> <span className="c8bku clfy9 ccolo cndda comjk cmwpt ce3ah">➜</span>
                                        </button>
                                    </div>
                                </form>

                                {/* Divider */}
                                <div className="cduop cwtaw c8og8">
                                    <div className="czhnl c9gvg c59y5 cycys" aria-hidden="true"></div>
                                    <div className="ca6yp cf37z c75bs">Or</div>
                                    <div className="czhnl c9gvg c75a8 cycys" aria-hidden="true"></div>
                                </div>

                                {/* Social login */}
                                <button className="cbr86 cvcg6 cnh8b cyky3 cwq9z cf37z c40tu cn582 c6kou c8og8" onClick={handleGoogleSignInWrapper}>
                                    <div className="cduop ce3tb c8og8">
                                        <svg className="ckiea ca4xu ctzpc cwcof c0mq4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.679 6.545H8.043v3.273h4.328c-.692 2.182-2.401 2.91-4.363 2.91a4.727 4.727 0 1 1 3.035-8.347l2.378-2.265A8 8 0 1 0 8.008 16c4.41 0 8.4-2.909 7.67-9.455Z"></path>
                                        </svg>
                                    </div>
                                    <span className="cmkam cvu8r cdelr">
                                        Continue With Google
                                        <span className="c8bku clfy9 ccolo ckiea comjk cy6kr cmwpt ce3ah">➜</span>
                                    </span>
                                </button>

                                <div className="cs9kl">
                                    <div className="ca6yp c9eyc">By signing into the platform you agree to our <a className="csjdn"
                                        href="#0">Terms & Conditions</a> and <a className="csjdn" href="#0">Privacy
                                            Policy</a>.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right side */}
                <SideTestimonial testimonials={sideTestimonialSigninData} />

                <Modal
                    show={showModal}
                    onClose={() => setShowModal(false)}
                    heading={modalHeading}
                    body={modalMessage}
                    primaryButtonText="OK"
                    secondaryButtonText="Cancel"
                    showButtons={false}
                    onPrimaryClick={() => setShowModal(false)}
                    onSecondaryClick={() => setShowModal(false)}
                />
            </div>

        </>
    );
};

export default Signin;