// src/components/AdminLogin/AdminLogin.jsx
import React, { useState } from 'react';


const AdminLogin = ({ onLoginSuccess }) => {
    const [adminId, setAdminId] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        const adminsCredential = JSON.parse(process.env.REACT_APP_ADMINS_CREDENTIAL);
        const matchedAdmin = adminsCredential.find(admin => admin.id === adminId && admin.password === password);
        if (matchedAdmin) {
            onLoginSuccess();
        } else {
            alert('Invalid admin ID or password');
        }
    };

    return (
        <div className="login-container">
            <h1 className="admin-heading">Sign in to Admin Dashboard</h1>
            <div className="admin-form-group">
                <label htmlFor="adminId">Admin ID</label>
                <input type="text" id="adminId" value={adminId} onChange={(e) => setAdminId(e.target.value)} />
            </div>
            <div className="admin-form-group">
                <label htmlFor="password">Password</label>
                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <button className="admin-btn-login" onClick={handleLogin}>Sign In</button>
        </div>
    );
};

export default AdminLogin;