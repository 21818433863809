import React from 'react';
import heroIllustration from '../../images/home/hero-illustration.svg';
import avatar01 from '../../images/home/avatar-01.jpg';
import avatar02 from '../../images/home/avatar-02.jpg';
import avatar03 from '../../images/home/avatar-03.jpg';
import avatar04 from '../../images/home/avatar-04.jpg';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseConfig/Firebase';

const Hero = () => {


    const navigate = useNavigate();

    const handlePostJobClick = () => {
        navigate('/list-job');  // Navigate to the job posting page
    };

    // Function to get the email of the current authenticated user
    const getCurrentUserEmail = () => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            return currentUser.email;
        } else {
            return 'No user logged in';
        }
    };

    return (


        <>

            <section className="cwq9z cj019">
                {/* Bg */}
                <div className="cetod c6kdq cjxga c8f1b cfcbm ctjxe cjcd5" aria-hidden="true"></div>

                {/* Illustration */}
                <div className="cetod c6kdq c8zq8 c8f1b cndjv codga c9rix" aria-hidden="true">
                    <img
                        src={heroIllustration}
                        className="co24w"
                        width="1440"
                        height="749"
                        alt="Hero Illustration"
                    />
                </div>

                <div className="cfd1l cnsl7 cwkz1 cwkio">
                    <div className="c8d05 cef3h c0ahj cm48u">
                        {/* Hero content */}
                        <div className="clxkp czbam chjqk">
                            {/* Copy */}
                            <h1 className="ckqm5 ccymi cuad0">
                                Freelancing made easy for {" "}
                                <span className="c1laq chgw8 cw2fq">Tech Projects</span>
                            </h1>
                            <p className="cjx7n cqdqx ca6yp">
                                Just post a project and the platform with the power of AI will take care of everything else from finding the perfect candidates to seamless project management and timely delivery. Our project managers works to make sure your project meets the best of your expectations.
                                <br className="cndjv c9rix" /> <br />   Apply to <b>Unlimited</b>  Tech Projects for <b>FREE</b>, <br />  Post <b>Unlimited</b>  Tech Projects for <b>FREE</b>.
                                {/* <br /> This Website is still under construction and not READY. For any doubt or query please contact +91 8779405144 or email at {getCurrentUserEmail()} */}
                            </p>
                            {/* Button + Avatars */}
                            <div className="cswe3 cp1au c8p1b crj19 c6rln cclz8 cua4c">
                                <div>
                                    <button
                                        className="c0tyr ce7qj cyky3 ctpla czwz3"
                                        onClick={handlePostJobClick}
                                    >
                                        <b>Post a Project</b>
                                    </button>
                                </div>
                                <div className="czodn cp1au c8p1b crj19 cypyb cclz8">
                                    <div className="cs3h1 cy6kr csd9h">
                                        <img
                                            className="c52nt c1g5q caz15 c4146"
                                            src={avatar01}
                                            width="32"
                                            height="32"
                                            alt="Avatar 01"
                                        />
                                        <img
                                            className="c52nt c1g5q caz15 c4146"
                                            src={avatar02}
                                            width="32"
                                            height="32"
                                            alt="Avatar 02"
                                        />
                                        <img
                                            className="c52nt c1g5q caz15 c4146"
                                            src={avatar03}
                                            width="32"
                                            height="32"
                                            alt="Avatar 03"
                                        />
                                        <img
                                            className="c52nt c1g5q caz15 c4146"
                                            src={avatar04}
                                            width="32"
                                            height="32"
                                            alt="Avatar 04"
                                        />
                                    </div>
                                    <div className="cf37z c4vrg ca6yp">
                                        Projects worth <span className="cw2fq cu1zo"> <b>₹ 100,000+</b></span> Completed
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Hero;