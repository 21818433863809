import React from "react";
import SectionHeader from "../../components/Legal/SectionHeader";
import InfoContainer from "../../components/Legal/InfoContainer";
import { privacyPolicyData } from "../../data/legalData";
import "../../CSS/Terms.css";

const PrivacyPolicy = () => {
    const { companyName, text } = privacyPolicyData.intro;

    return (
        <div className="terms-and-conditions-container terms-and-conditions-fadeIn">
            <h1 className="terms-and-conditions-heading">Privacy Policy</h1>

            <p className="terms-and-conditions-intro">
                At <b>{companyName}</b>, {text}
            </p>

            {privacyPolicyData.sections.map((section) => (
                <div key={section.number} className="terms-and-conditions-section">
                    <SectionHeader
                        number={section.number}
                        title={section.title}
                    />
                    <p className="terms-and-conditions-paragraph">
                        {section.introText}
                    </p>

                    {section.infoContainers.map((container) => (
                        <InfoContainer
                            key={container.heading}
                            heading={container.heading}
                            details={container.details}
                            isListType={container.isListType ?? true}
                        />
                    ))}
                </div>

            ))}



        </div>
    );
};

export default PrivacyPolicy;