import React from "react";
import PropTypes from "prop-types";

const SectionHeader = ({ number, title }) => (
    number && title && (
        <h4>
            <span className="terms-and-conditions-number terms-and-conditions-blue">{number}</span>
            <span className="terms-and-conditions-title terms-and-conditions-blue">{title}</span>
        </h4>
    )
);

SectionHeader.propTypes = {
    number: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default SectionHeader;