import React, { useState } from 'react';
import { toast } from "react-toastify";

const OptionSelectionBox = ({ options, selectedOptions, setSelectedOptions, onSave, maxSelection }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleOptionChange = (option) => {
        if (selectedOptions.length >= maxSelection && !selectedOptions.includes(option)) {
            toast.warning(`You cannot select more than ${maxSelection} options.`);
            return;
        }
        setSelectedOptions(prevOptions =>
            prevOptions.includes(option)
                ? prevOptions.filter(s => s !== option)
                : [...prevOptions, option]
        );
    };

    const handleSave = (e) => {
        e.preventDefault();
        onSave();
    };

    const filteredOptions = options.filter(option =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="tech-stack-dropdown" style={{ maxHeight: '300px', minWidth: '250px', overflowY: 'auto', position: 'relative' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', position: 'sticky', top: -17, zIndex: 1, backgroundColor: 'white' }}>
                <div className="search-bar-container">
                    <input
                        type="text"
                        placeholder="Search options..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ width: '100%', padding: '10px', margin: '10px 0', border: '1px solid #ccc', borderRadius: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                    />
                </div>
                <button className="cf37z c4vrg cw2fq c4von" onClick={handleSave}>
                    Save
                </button>
            </div>

            {filteredOptions.map(option => (
                <div key={option}>
                    <input
                        type="checkbox"
                        id={option}
                        className="cicja"
                        checked={selectedOptions.includes(option)}
                        onChange={() => handleOptionChange(option)}
                    />
                    <label htmlFor={option} className="cvhuf cf37z cm87k">
                        {option}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default OptionSelectionBox;