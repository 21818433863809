export const privacyPolicyData = {
    intro: {
        companyName: "Project Mela",
        text: "we respect your privacy and are committed to protecting your personal information. By using our platform, you acknowledge and consent to the terms outlined in this Privacy Policy. This document explains how your information is collected, used, stored, and disclosed to enhance your experience and improve our platform. If you do not agree to these terms, you must refrain from using the platform."
    },
    sections: [
        {
            number: "1.",
            title: "Information We Collect",
            introText: "We collect information to deliver, optimize, and enhance the services we provide. By using the platform, you consent to the collection and use of the following types of information:",
            infoContainers: [
                {
                    heading: "1.1 Information You Provide Directly",
                    details: [
                        "Identity Verification Data: Government-issued IDs (e.g., Aadhar Card, PAN Card, Passport, or Voter ID) submitted by Candidates for authentication and compliance purposes.",
                        "Contact Information: Name, email address, phone number, and other account details.",
                        "Payment Information: Bank account details, transaction history, and payment preferences for processing payments securely.",
                        "Project Data: Information related to the projects you post, participate in, or manage, including communications with other users."
                    ]
                },
                {
                    heading: "1.2 Information Collected Automatically",
                    details: [
                        "Device and Usage Data: IP addresses, browser types, device identifiers, pages visited, and interactions on the platform.",
                        "Cookies and Tracking Technologies: Data collected via cookies, web beacons, and similar tools to improve functionality and user experience."
                    ]
                },
                {
                    heading: "1.3 Information from Third-Party Services",
                    details: "We use Google Firebase for authentication, data storage, and operational support. By using the platform, you agree to the collection and processing of data through Firebase services, which adhere to their own privacy policies and security standards.",
                    isListType: false
                }
            ]
        },
        {
            number: "2.",
            title: "How We Use Your Information",
            introText: "By using Project Mela, you consent to the use of your information for the following purposes:",
            infoContainers: [
                {
                    heading: "2.1 Service Delivery and Improvement",
                    details: [
                        "To create and manage your account.",
                        "To authenticate your identity and maintain platform security.",
                        "To connect you with Clients or Candidates for project collaboration.",
                        "To provide customer support and respond to inquiries."
                    ]
                },
                {
                    heading: "2.2 Enhancement and AI Development",
                    details: [
                        "To improve the platform's performance and functionality.",
                        "To analyze usage trends for platform optimization.",
                        "To develop, train, and enhance AI-related features and tools, which may involve anonymized data analysis."
                    ]
                },
                {
                    heading: "2.3 Compliance and Security",
                    details: [
                        "To ensure compliance with legal, regulatory, and contractual obligations.",
                        "To protect the platform and its users from fraud, unauthorized access, or violations of our Terms and Conditions."
                    ]
                }
            ]
        },
        {
            number: "3.",
            title: "Data Retention and Storage",
            introText: "We store data securely using Google Firebase, which adheres to industry-standard practices for encryption and security. By using our platform, you consent to the following retention policies:",
            infoContainers: [
                {
                    heading: "3.1 Retention Policies",
                    details: [
                        "Active Users: Your data will be retained as long as your account remains active.",
                        "Inactive Accounts: If your account becomes inactive, we may retain your information for a reasonable period for legal, operational, or regulatory purposes.",
                        "Deletion Requests: You may request the deletion of your data, subject to compliance with our legal and contractual obligations."
                    ]
                }
            ]
        },
        {
            number: "4.",
            title: "Sharing Your Information",
            introText: "By using our platform, you agree to the following data-sharing practices:",
            infoContainers: [
                {
                    heading: "4.1 With Clients or Candidates",
                    details: [
                        "Identity Verification Details: Shared between Clients and Candidates when necessary for compliance, dispute resolution, or legal purposes."
                    ]
                },
                {
                    heading: "4.2 With Third-Party Services",
                    details: [
                        "Service Providers: Google Firebase, payment processors, and analytics services may access your information to support our operations. These providers operate under strict security protocols."
                    ]
                },
                {
                    heading: "4.3 For Legal Compliance",
                    details: [
                        "We may share your information to comply with legal obligations, enforce our policies, or respond to valid legal requests."
                    ]
                }
            ]
        },
        {
            number: "5.",
            title: "Your Responsibilities and Rights",
            introText: "By using the platform, you acknowledge and agree to:",
            infoContainers: [
                {
                    heading: "5.1 User Responsibilities",
                    details: [
                        "Provide accurate and up-to-date information during registration and project participation.",
                        "Keep your account credentials secure and confidential."
                    ]
                },
                {
                    heading: "5.2 User Rights",
                    details: [
                        "Request data access, correction, or deletion through our support team if needed."
                    ]
                }
            ]
        },
        {
            number: "6.",
            title: "Security Measures",
            introText: "We implement robust security measures to protect your data, including:",
            infoContainers: [
                {
                    heading: "6.1 Security Practices",
                    details: [
                        "Encryption: All sensitive data is encrypted in transit and at rest.",
                        "Access Restrictions: Only authorized personnel can access user data.",
                        "Monitoring: Regular audits and monitoring of systems to identify vulnerabilities."
                    ]
                },
                {
                    heading: "6.2 Disclaimer",
                    details: [
                        "While we strive to protect your information, you acknowledge that no system can guarantee absolute security."
                    ]
                }
            ]
        },
        {
            number: "7.",
            title: "Cookie Policy",
            introText: "Project Mela uses cookies and similar tracking technologies to enhance your user experience, analyse platform performance, and provide tailored services. By accessing or using the platform, you consent to our use of cookies and tracking technologies as described in this policy.",
            infoContainers: [
                {
                    heading: "7.1 What Are Cookies?",
                    details: [
                        "Cookies are small text files stored on your device (computer, smartphone, tablet) when you visit a website. These files collect and store information that helps us recognize your preferences, optimize functionality, and improve user experience."
                    ]
                },
                {
                    heading: "7.2 Types of Cookies We Use",
                    details: [
                        "Essential Cookies: These cookies are necessary for the core functionality of the platform. They enable features like account authentication, secure login, and navigation across pages. Disabling these cookies may prevent you from accessing key features of the platform.",
                        "Performance and Analytics Cookies: These cookies collect information about how users interact with the platform, such as pages visited, time spent, and errors encountered. We use this data to monitor platform performance and improve usability.",
                        "Functional Cookies: These cookies remember your preferences, such as language selection or default settings, to enhance your experience.",
                        "Advertising and Marketing Cookies: These cookies may be used to deliver tailored advertisements or promotional content based on your interactions with the platform.",
                        "Third-Party Cookies: We may also use third-party cookies from trusted providers like Google Analytics or Firebase to analyze platform usage and support operational needs."
                    ]
                },
                {
                    heading: "7.3 How We Use Cookies and Tracking Technologies",
                    details: [
                        "Enhancing User Experience: To remember your preferences, settings, and login information. To provide a seamless and personalized experience during your interactions with the platform.",
                        "Platform Analytics and Optimization: To monitor user behavior, identify usage trends, and optimize platform performance. To detect and resolve technical issues or bugs.",
                        "Marketing and Advertising: To deliver relevant promotional content based on your activity on the platform.",
                        "Security and Compliance: To enhance platform security by monitoring unauthorized access or misuse."
                    ]
                },
                {
                    heading: "7.4 Managing Your Cookie Preferences",
                    details: [
                        "Accept or Reject Cookies: Adjust your browser settings to accept or reject all or specific types of cookies.",
                        "Delete Cookies: Clear existing cookies stored on your device.",
                        "Disable Tracking Technologies: Use browser extensions or opt-out tools to block tracking technologies.",
                        "Note: Disabling certain cookies may limit the functionality of the platform, such as logging in, saving preferences, or accessing specific features."
                    ]
                },
                {
                    heading: "7.5 Third-Party Services and Cookies",
                    details: [
                        "Project Mela integrates third-party services, such as Google Firebase and analytics providers, which may use cookies and tracking technologies as per their own privacy policies. By using the platform, you consent to the data collection and processing practices of these providers. We recommend reviewing their policies for more details."
                    ]
                },
                {
                    heading: "7.6 Updates to This Cookie Policy",
                    details: [
                        "This Cookie Policy may be updated periodically to reflect changes in technology, legal requirements, or platform functionality. You will be notified of significant changes, and continued use of the platform constitutes acceptance of the updated policy."
                    ]
                }
            ]
        },
        {
            number: "8.",
            title: "Children’s Privacy",
            introText: "Project Mela is designed exclusively for individuals aged 18 and above. By accessing or using the platform, you confirm and warrant that you are at least 18 years old. If a minor under the age of 18 uses this platform, it is assumed that they are doing so under the explicit supervision and consent of their parent or legal guardian. The parent or guardian is solely responsible for overseeing the minor’s activities on the platform.",
            infoContainers: [
                {
                    heading: "8.1 Liability Disclaimer for Minor Misrepresentation",
                    details: [
                        "Age Misrepresentation: If a minor falsifies their age or identity to create an account or gain access to the platform.",
                        "Financial Loss: Any financial transactions, disputes, or losses incurred as a result of a minor’s unauthorized or unsupervised use of the platform.",
                        "Inappropriate Use of Resources: Any misuse of tools, resources, or project-related materials by a minor.",
                        "Contractual Violations: Failure to fulfill obligations or violations of agreements caused by a minor’s inability to comply with the terms of use.",
                        "Data Privacy Issues: Sharing, misuse, or unauthorized disclosure of personal or project-related information by the minor."
                    ]
                },
                {
                    heading: "8.2 Immediate Action for Violations",
                    details: [
                        "Suspend or terminate the account immediately.",
                        "Take necessary actions to delete any information associated with the account.",
                        "Inform parents or guardians (if identifiable) to address the situation."
                    ]
                }
            ]
        },
        {
            number: "9.",
            title: "Updates to the Privacy Policy",
            introText: "We reserve the right to update this Privacy Policy at any time to reflect changes in our practices or for legal and regulatory compliance. You will be notified of significant updates via the platform or email. Continued use of the platform constitutes your agreement to the revised Privacy Policy.",
            infoContainers: []
        },
        {
            number: "10.",
            title: "Contact Us",
            introText: "If you have questions or concerns about this Privacy Policy, you may contact us at:",
            infoContainers: [
                {
                    heading: "Contact Information",
                    details: [
                        "Email: contact@projectmela.com",
                        "Address: Teraforge Digital Lab LLP, W06 Quality Homes, Lodha Crown, Near Majiwada Flyover, Thane, Maharashtra, India - 400601"
                    ]
                }
            ]
        },



    ]
};

// Rich Text index

// [b]bold text[/b]
// [i]italic text[/i]
// [u]underlined text[/u]
// [a href = 'url']link text[/a]
// [tab]This text will be indented with a left padding of 2rem.[/tab]

export const TermsAndConditionsData = {
    intro: {
        companyName: "Project Mela",
        text: "Welcome to Project Mela, an online platform provided by Teraforge Digital Lab LLP (\"we,\" \"our,\" \"us\") based in Maharashtra, India. These Terms and Conditions (\"Terms\") govern your access and use of the platform and its services, whether you are a Client posting projects or a Candidate undertaking projects.\n\nBy registering, accessing, or using the platform, you acknowledge that you have read, understood, and agree to abide by these Terms. If you disagree with these Terms, you must immediately cease using the platform."
    },
    sections: [
        {
            number: "1.",
            title: "Nature of the Platform",
            introText: "Project Mela operates as an intermediary platform connecting Clients who post projects with Candidates who provide services. The platform facilitates communication, collaboration, and project management but does not guarantee outcomes or take responsibility for individual user actions. We do not employ or directly supervise Candidates or Clients, and therefore, any disputes, liabilities, or legal issues arising between them are their sole responsibility. By using our platform, you accept this limitation of liability.",
            infoContainers: []
        },
        {
            number: "2.",
            title: "Disclaimer of Liability",
            introText: "As a facilitator, Project Mela shall not be held responsible for any damages, misconduct, or disputes arising from the actions of users.",
            infoContainers: [
                {
                    heading: "Specific Disclaimers",
                    details: [
                        { text: "‎ ", isListItem: false },
                        { text: "[b]Candidate Actions:[/b] We cannot guarantee or validate the intent, skills, or professionalism of Candidates registered on the platform. While we have a vetting process, this does not ensure the complete accuracy of their credentials or their ability to meet project expectations.", isListItem: true },
                        { text: "[b]Intellectual Property Infringements:[/b] We are not liable for copyright violations, trademark misuse, or other intellectual property breaches by Candidates. In such instances, we will cooperate with the aggrieved party and provide identity verification details of the involved Candidate.", isListItem: true },
                        { text: "[b]Malicious Intent:[/b] Any fraud, theft, hacking, dissemination of viruses, or other harmful activities perpetrated by Candidates or Clients is beyond our responsibility. Affected users may pursue legal remedies independently.", isListItem: true },
                        { text: "[b]Confidentiality Breaches:[/b] Although all users agree to a [a href='https://firebasestorage.googleapis.com/v0/b/project-mela.appspot.com/o/Legal%2FNon%20Disclosure%20Agreement%20Project%20Mela.pdf?alt=media&token=f860640b-f09b-4452-901e-b1b5f6f0a8c2']Non-Disclosure Agreement (NDA)[/a]  upon registration, any violations of this NDA by a Candidate or Client will not hold the platform liable.", isListItem: true },
                        { text: "[b]Third-Party Losses:[/b] We are not accountable for losses incurred due to delays, project failures, or disputes between Candidates and Clients.", isListItem: true }
                    ]
                }
            ]
        },
        {
            number: "3.",
            title: "User Obligations",
            introText: "All users of the platform, including Candidates and Clients, must comply with the following obligations:",
            infoContainers: [
                {
                    heading: "3.1 Candidate Responsibilities",
                    details: [
                        { text: "‎ ", isListItem: false },
                        { text: "[b]1) Submission of Identity Proof:[/b]", isListItem: false },
                        { text: "Candidates must submit valid government-issued identification documents before engaging in any project. Accepted documents include, but are not limited to:", isListItem: false },
                        { text: "Aadhar Card", isListItem: true, tabLevel: 1 },
                        { text: "PAN Card", isListItem: true, tabLevel: 1 },
                        { text: "Passport", isListItem: true, tabLevel: 1 },
                        { text: "Voter ID", isListItem: true, tabLevel: 1 },

                        { text: "By submitting these documents, Candidates explicitly consent to their information being used for identity verification, legal compliance, and dispute resolution purposes. This information may be shared with relevant authorities or affected parties if required to address violations, fraud, or other misconduct.", isListItem: false },
                        { text: "[b]2) Compliance with Laws and Agreements:[/b]", isListItem: false },
                        { text: "Candidates agree to comply with all applicable laws and platform-specific policies, including but not limited to:", isListItem: false },
                        { tabLevel: 1, text: "[b]Non-Disclosure Agreements (NDA):[/b] All project details and deliverables must remain confidential and used strictly within the project scope.", isListItem: true },

                        { tabLevel: 1, text: "[b]Intellectual Property and Copyright Laws:[/b] Candidates must ensure that all work is original and free from infringement of third-party rights.", isListItem: true },
                        { text: "[b]3) Responsibility for Project Outcomes:[/b]", isListItem: false },
                        { text: "Candidates must complete projects as per the agreed-upon timelines, scope, and quality standards. Any negligence, delays, or failure to meet these obligations may result in penalties, including withholding of payments, suspension of accounts, or legal action.", isListItem: false },

                        { text: "[b]4) Avoiding External Solicitation:[/b]", isListItem: false },
                        { text: "Candidates are prohibited from soliciting Clients for direct transactions outside the platform. Such actions undermine the integrity of the platform and will result in immediate suspension or termination of the Candidate’s account.", isListItem: false },

                        { text: "[b]5) Assumption of Liability:[/b]", isListItem: false },
                        { text: "Candidates acknowledge that they bear sole responsibility for their work, including:", isListItem: false },
                        { tabLevel: 1, text: "Any legal liabilities arising from intellectual property infringement, copyright violations, or unauthorized use of materials.", isListItem: true },
                        { tabLevel: 1, text: "Damages caused to Clients or third parties due to negligence, malpractice, or malicious intent.", isListItem: true },
                        { text: "[b]6) Cooperation with Investigations:[/b]", isListItem: false },
                        { text: "In the event of disputes, violations, or misconduct, Candidates agree to cooperate fully with platform moderators and provide all necessary information for resolution.", isListItem: false },
                        { text: "Failure to comply with these responsibilities will result in immediate action, including suspension of the account, forfeiture of earnings, reporting to authorities, and potential legal consequences.", isListItem: false },
                        { text: "‎ ", isListItem: false },
                    ]
                },
                {
                    heading: "3.2 Client Responsibilities",
                    details: [
                        { text: "‎ ", isListItem: false },
                        { text: "[b]1) Provision of Complete and Accurate Project Details:[/b]", isListItem: false },
                        { text: "The Client explicitly agrees to provide clear, complete, and accurate project information, including but not limited to the project scope, expected deliverables, timelines, and other relevant specifications. The Client understands that any omissions or ambiguities in the initial project details may lead to delays or the need for additional costs to accommodate the missing information.", isListItem: false },
                        { text: "By confirming the project details with the platform, the Client consents to proceed strictly in accordance with the agreed scope of work as outlined in the project proposal.", isListItem: false },

                        { text: "[b]2) Adherence to the Scope of Work:[/b]", isListItem: false },
                        { text: "The Client agrees that all work undertaken will be limited to the features, deliverables, and services explicitly stated in the project scope and proposal provided by the platform. The Client consents that:", isListItem: false },
                        { tabLevel: 1, text: "Any requests for additional features, modifications, or changes that are not included in the original scope shall be treated as a new project or an additional service, subject to separate charges as determined by the platform.", isListItem: true },
                        { tabLevel: 1, text: "The Client shall not withhold payments or delay approvals for work delivered within the agreed scope by claiming additional features or modifications not included in the proposal.", isListItem: true },

                        { text: "[b]3) Timely Payment for Services Rendered:[/b]", isListItem: false },
                        { text: "The Client agrees to make timely payments as per the agreed payment schedule and method (e.g., upfront payment, milestone-based payments). In the event of delays or non-payment:", isListItem: false },
                        { tabLevel: 1, text: "The platform reserves the right to pause or terminate project activities until payment is made in full.", isListItem: true },
                        { tabLevel: 1, text: "The Client agrees to pay any fines, penalties, or interest charges for delayed payments, which will be communicated in advance to the Client’s registered email. Persistent non-payment may result in legal proceedings or suspension of the Client’s account.", isListItem: true },

                        { text: "[b]4) Due Diligence and Review Period:[/b]", isListItem: false },
                        { text: "The Client consents to a 15-day review period after the completion of the project to thoroughly evaluate the deliverables. During this time, the Client may request revisions strictly within the scope of the original project agreement.", isListItem: false },
                        { tabLevel: 1, text: "The Client acknowledges that it is their sole responsibility to conduct due diligence during this period and ensure that all features and deliverables meet their requirements.", isListItem: true },
                        { tabLevel: 1, text: "The Client agrees that after the 15-day period, the platform will not be held liable for any missed features, modifications, or changes that were overlooked during the due diligence. Any new requests after this period will be treated as additional work and will incur separate charges.", isListItem: true },

                        { text: "[b]5) Responsibility for Necessary Resources:[/b]", isListItem: false },
                        { text: "The Client agrees to provide all required materials, information, and resources necessary for the completion of the project in a timely manner. Any delay in providing these resources may result in project delays, for which the platform is not responsible.", isListItem: false },

                        { text: "[b]6) Acknowledgment of Legal and Financial Obligations:[/b]", isListItem: false },
                        { text: "The Client consents to pay all applicable fees, including fines or penalties, as outlined for non-payment or delayed payment. The platform will inform the Client of such charges in advance via email, and the Client agrees to settle them within the stipulated time frame to avoid further action.", isListItem: false },

                        { text: "‎ ", isListItem: false },
                        { text: "By utilizing the platform, the Client acknowledges and agrees to these responsibilities. Failure to adhere to these obligations may result in project delays, termination of services, or additional charges or legal actions. ", isListItem: false },

                    ]
                },
            ],
        },
        {
            number: "4.",
            title: "Payment Terms",
            introText: "The Client acknowledges and agrees to the following payment terms and conditions for projects undertaken through the Project Mela platform:",
            infoContainers: [
                {
                    heading: "",
                    details: [

                        { text: "[b]4.1 Standard Payment Method:[/b]", isListItem: false },
                        { text: "The Client is expected to make the full payment upfront for the project before any work commences. This is the default payment method to ensure seamless execution and commitment to project timelines.", isListItem: false },

                        { text: "[b]4.2 Alternative Payment Methods:[/b]", isListItem: false },
                        { text: "In certain cases, based on the scale, complexity, or unique requirements of a project, and at the sole discretion and recommendation of a Project Mela Team member, the platform may offer alternative payment methods. These include:", isListItem: false },
                        { tabLevel: 1, text: "[b]1) 50%-50% Split Payment:[/b]", isListItem: false },
                        { tabLevel: 2, text: "	The Client pays 50% of the total project cost upfront before the work begins.", isListItem: true },
                        { tabLevel: 2, text: "	The remaining 50% must be paid either:", isListItem: true },
                        { tabLevel: 3, text: "Within 15 days of the first payment, or", isListItem: false },
                        { tabLevel: 3, text: "Upon project completion, whichever comes first.", isListItem: false },
                        { tabLevel: 1, text: "[b]2) Milestone-Based Payment (Pay-As-You-Go):[/b]", isListItem: false },
                        { tabLevel: 2, text: "	The Client pays for the specific milestone outlined in the project proposal before work on that milestone begins.", isListItem: true },
                        { tabLevel: 2, text: "	Work for each subsequent milestone will not commence until payment for that milestone has been received in full.", isListItem: true },
                        { tabLevel: 2, text: "	Any delays in milestone payments may result in corresponding delays in project timelines, for which the platform will not be held responsible.", isListItem: true },
                        { text: "The platform reserves the right to determine the eligibility of a project for these alternative payment cycles and to deny these methods at its sole discretion.", isListItem: false },

                        { text: "[b]4.3 Client Consent to Financial Obligations:[/b]", isListItem: false },
                        { text: "By using the platform, the Client consents to the following:", isListItem: false },
                        { tabLevel: 1, text: "[b] 1) Adherence to Payment Schedules:[/b]", isListItem: false },
                        { tabLevel: 2, text: "	The Client agrees to pay all required amounts according to the chosen payment method without delay.", isListItem: true },
                        { tabLevel: 2, text: "	The Client understands that any failure to pay on time may result in the suspension of project activities until the payment is received.", isListItem: true },
                        { tabLevel: 1, text: "[b]2) Interest, Penalties, and Fees for Delayed Payments:[/b]", isListItem: false },
                        { tabLevel: 2, text: "	In cases of delayed payment, the Client agrees to pay any applicable interest, penalty, or administrative fees as determined by the platform.", isListItem: true },
                        { tabLevel: 2, text: "	These charges will be communicated to the Client via their registered email address in advance, and the Client is obligated to settle them promptly to resume project activities.", isListItem: true },

                        { text: "[b]4.4 Suspension or Termination Due to Non-Payment:[/b]", isListItem: false },
                        { text: "If the Client fails to make payments as required:", isListItem: false },
                        { tabLevel: 1, text: "	The platform reserves the right to pause all project-related work, including access to deliverables or further communication with Candidates.", isListItem: true },
                        { tabLevel: 1, text: "  Persistent delays or non-payment may result in termination of the project agreement and further legal action to recover unpaid amounts.", isListItem: true },
                        { text: "‎ ", isListItem: false },
                    ]
                },
            ],
        },
        {
            number: "5.",
            title: "Ownership of Deliverables",
            introText: "",
            infoContainers: [

                {
                    heading: "",
                    details: [
                        { text: "‎ ", isListItem: false },
                        { text: "[b]5.1 Transfer of Ownership:[/b]", isListItem: false },
                        { text: "Ownership of the project deliverables, including all associated intellectual property rights, will be transferred to the Client only upon the receipt of the full payment for the project. This transfer includes all items explicitly outlined in the project agreement, such as:", isListItem: false },
                        { tabLevel: 1, text: " 	Code: Shared with the Client via a GitHub repository or other agreed platforms.", isListItem: true },
                        { tabLevel: 1, text: " 	Designs: Delivered as editable, customizable design files (e.g., Figma, Adobe XD, Photoshop, or similar formats).", isListItem: true },
                        { tabLevel: 1, text: " 	Other Assets: As specified in the project scope or proposal, including documentation, prototypes, or additional resources.", isListItem: true },

                        { text: "[b]5.2 Client Responsibilities Post-Handover:[/b]", isListItem: false },
                        { text: "Once the deliverables are handed over to the Client, the following terms apply:", isListItem: false },
                        { tabLevel: 1, text: "1.	The Client assumes full ownership of the project along with all associated rights and liabilities.", isListItem: true },
                        { tabLevel: 1, text: "2.	It is the Client’s sole responsibility to conduct thorough due diligence on the delivered project. This includes ensuring that all features, functionalities, and requirements align with the project scope.", isListItem: true },
                        { tabLevel: 1, text: "3.	The platform, Project Mela, and its associated entities will no longer be held liable for:", isListItem: true },
                        { tabLevel: 2, text: "➤	Future modifications or enhancements to the project.", isListItem: false },
                        { tabLevel: 2, text: "➤	Issues arising due to misuse, alterations, or third-party interference.", isListItem: false },
                        { tabLevel: 2, text: "➤	Any legal, operational, or technical disputes related to the project post-delivery.", isListItem: false },

                        { text: "[b]5.3 Retention of Ownership by the Platform:[/b]", isListItem: false },
                        { text: "Until full payment is received, the ownership of all deliverables remains with the platform. This includes, but is not limited to, all code, designs, documentation, and other project-related assets. The platform reserves the right to:", isListItem: false },
                        { tabLevel: 1, text: "1.	Withhold Delivery: Prevent access to or sharing of deliverables with the Client until payment is made in full.", isListItem: true },
                        { tabLevel: 1, text: "2.	Retain Full Rights: Retain full rights over the deliverables, including the right to:", isListItem: true },
                        { tabLevel: 2, text: "➤	Sell, license, lease, or distribute the project or its components.", isListItem: false },
                        { tabLevel: 2, text: "➤	Use the code, designs, or other assets for internal purposes or other client projects.", isListItem: false },
                        { tabLevel: 2, text: "➤	Modify, repurpose, or recycle any unpaid deliverables for commercial or non-commercial use.", isListItem: false },

                        { text: "[b]5.4 Non-Payment or Delayed Payment:[/b]", isListItem: false },
                        { text: "In cases of non-payment or significant delays in payment, despite prior warnings and notifications sent to the Client:", isListItem: false },
                        { tabLevel: 1, text: "1.	The platform will retain all rights to the deliverables and may take appropriate actions, including selling or leasing the deliverables to recover unpaid costs.", isListItem: true },
                        { tabLevel: 1, text: "2.	The platform reserves the right to terminate the Client’s access to the project and pursue legal remedies to recover outstanding payments or damages.", isListItem: true },

                        { text: "[b]5.5 No Liability Post-Handover:[/b]", isListItem: false },
                        { text: "Once ownership has been transferred, the Client acknowledges that the platform is no longer liable for any aspect of the project. This includes but is not limited to:", isListItem: false },
                        { tabLevel: 1, text: " 	Issues related to the deployment, use, or operation of the project.", isListItem: true },
                        { tabLevel: 1, text: " 	Security vulnerabilities, legal disputes, or third-party claims associated with the project.", isListItem: true },
                        { tabLevel: 1, text: " 	Any alterations or enhancements made to the deliverables by the Client or third parties.", isListItem: true },
                        { text: "By proceeding with the platform, the Client explicitly agrees to these terms regarding ownership and liability.", isListItem: false },

                    ]
                },
            ],
        },


        {
            number: "6.",
            title: "Refund Policy",
            introText: "At Project Mela, we are committed to providing a reliable platform for project execution. However, refunds are governed by strict guidelines to ensure fairness to all parties involved. By using the platform, the Client agrees to the following refund terms:",
            infoContainers: [{
                heading: "",
                details: [
                    { text: "‎ ", isListItem: false },



                    { text: "[b]6.1 Conditions for Refund Eligibility[/b]", isListItem: false },
                    { text: "Refunds may be granted only under the following specific conditions:", isListItem: false },
                    { tabLevel: 1, text: "[b]1) Non-Commencement of Work Within 30 Days:[/b]", isListItem: false },
                    { tabLevel: 2, text: "The Client may request a refund if no substantial work has been delivered or shown within 30 days of payment, despite repeated follow-ups.", isListItem: false },
                    { tabLevel: 2, text: " Substantial work is defined as any significant progress, such as designs, prototypes, or a written codebase of at least 2,000 characters or more. If such work has commenced, the project will no longer qualify for a refund.", isListItem: true },
                    { tabLevel: 2, text: " Refund requests cannot be made prior to the completion of this 30-day period.", isListItem: true },
                    { tabLevel: 1, text: "[b]2) Delays Due to Unforeseen Circumstances:[/b]", isListItem: false },
                    { tabLevel: 2, text: "Refunds will be considered only when delays arise due to issues such as:", isListItem: false },
                    { tabLevel: 3, text: " Difficulty in finding suitable Candidates for the project.", isListItem: true },
                    { tabLevel: 3, text: " Budget constraints preventing Candidates from accepting the project.", isListItem: true },
                    { tabLevel: 3, text: " Technical or operational issues that prevent the work from starting.", isListItem: true },
                    { text: "‎ ", isListItem: false },

                    { text: "[b]6.2 Situations Where Refunds Are Not Allowed[/b]", isListItem: false },
                    { text: "The following scenarios do not qualify for a refund:", isListItem: false },
                    { tabLevel: 1, text: "[b]1) Work Already Started or Delivered:[/b]", isListItem: false },
                    { tabLevel: 2, text: " If work has commenced (e.g., designs, written code, or prototypes) or if the Client has already received any deliverables, refunds will not be granted, even if the Client is dissatisfied with the results.", isListItem: true },
                    { tabLevel: 1, text: "[b]2) Dissatisfaction with the Outcome:[/b]", isListItem: false },
                    { tabLevel: 2, text: " The platform and its Candidates strive to meet the Client’s expectations. However, due to the subjective nature of creative or technical work, the deliverables may not always align perfectly with the Client’s vision. This does not qualify for a refund.", isListItem: true },
                    { tabLevel: 2, text: " Clients are encouraged to communicate their expectations clearly and utilize the 15-day revision period to address any concerns.", isListItem: true },
                    { tabLevel: 1, text: "[b]3) Unjustified Termination of Work:[/b]", isListItem: false },
                    { tabLevel: 2, text: " Clients cannot terminate the project arbitrarily and request a refund for work already done or in progress.", isListItem: true },
                    { text: "‎ ", isListItem: false },

                    { text: "[b]6.3 Resolving Disputes Over Quality[/b]", isListItem: false },
                    { text: "In cases where the Client believes the work is substandard:", isListItem: false },
                    { tabLevel: 1, text: "• The platform may, at its sole discretion, replace the assigned Candidates or teams with new individuals or moderators.", isListItem: false },
                    { tabLevel: 1, text: "• This replacement process is determined by the Project Mela team if they deem the work done to be below quality standards or not aligned with the project scope.", isListItem: false },
                    { tabLevel: 1, text: "• Such decisions will be made in the best interest of the project and are not grounds for a refund.", isListItem: false },
                    { text: "‎ ", isListItem: false },

                    { text: "[b]6.4 Refund Processing[/b]", isListItem: false },
                    { tabLevel: 1, text: " If a refund request is approved, the refund will be processed within 14 days following the conclusion of the 30-day period of inactivity.", isListItem: true },
                    { tabLevel: 1, text: " Partial refunds for incomplete projects may be considered on a case-by-case basis, depending on the work already completed and the effort invested.", isListItem: true },
                    { text: "‎ ", isListItem: false },

                    { text: "[b]6.5 Client Acknowledgment[/b]", isListItem: false },
                    { text: "By using the platform, the Client agrees to these refund terms and acknowledges that:", isListItem: false },
                    { tabLevel: 1, text: "• Refunds are a last resort, applicable only when no substantial work has been initiated within the specified timeframe.", isListItem: false },
                    { tabLevel: 1, text: "• Any dissatisfaction with completed or ongoing work does not qualify for a refund, but the platform will make reasonable efforts to address concerns through revisions or reassignment of resources.", isListItem: false },
                    { tabLevel: 1, text: "• The decision to grant a refund or replace team members lies solely with the platform.", isListItem: false },

                ]
            },],
        },


        {
            number: "7.",
            title: "Project Revisions and Post-Delivery",
            introText: "At Project Mela, we aim to ensure that all deliverables meet the Client's requirements while maintaining clear guidelines for revisions and post-delivery responsibilities. By using the platform, the Client agrees to the following terms regarding revisions and responsibilities after project completion:",
            infoContainers: [{
                heading: "",
                details: [
                    { text: "‎ ", isListItem: false },

                    { text: "[b]7.1 Revision Period[/b]", isListItem: false },
                    { tabLevel: 1, text: "[b]1) Duration for Revisions:[/b]", isListItem: false },
                    { tabLevel: 2, text: " Clients are granted a 15-day review period after the delivery of the project to thoroughly inspect the deliverables. During this time, they may request revisions or corrections.", isListItem: true },
                    { tabLevel: 1, text: "[b]2) Scope of Revisions:[/b]", isListItem: false },
                    { tabLevel: 2, text: " Revisions are strictly limited to adjustments, corrections, or refinements within the original agreed project scope as outlined in the proposal.", isListItem: true },
                    { tabLevel: 2, text: " Requests for features, changes, or additional work outside the original scope will not be considered as revisions and will be treated as new tasks, subject to additional charges.", isListItem: true },
                    { tabLevel: 1, text: "[b]3) Client Responsibility During the Review Period:[/b]", isListItem: false },
                    { tabLevel: 2, text: " The Client is responsible for conducting thorough due diligence during the 15-day period to ensure that all deliverables meet their expectations and align with the project scope.", isListItem: true },
                    { tabLevel: 2, text: " The platform and its team will make reasonable efforts to address any valid concerns raised within this period.", isListItem: true },
                    { text: "‎ ", isListItem: false },

                    { text: "[b]7.2 Work Outside the Revision Period[/b]", isListItem: false },
                    { tabLevel: 1, text: "[b]1) Post-15-Day Period:[/b]", isListItem: false },
                    { tabLevel: 2, text: "After the 15-day review period, any new requests for revisions, modifications, or adjustments—whether within or beyond the original project scope—will be considered new tasks and will incur additional costs as agreed upon between the Client and the platform.", isListItem: true },
                    { tabLevel: 2, text: "The platform will not be held liable for any discrepancies, errors, or omissions that were not identified by the Client during the revision period.", isListItem: true },
                    { tabLevel: 1, text: "[b]2) New Features or Enhancements:[/b]", isListItem: false },
                    { tabLevel: 2, text: "If the Client requests features, updates, or enhancements not included in the original agreement, such requests will be treated as new work, requiring a separate proposal, timeline, and budget.", isListItem: true },
                    { text: "‎ ", isListItem: false },

                    { text: "[b]7.3 Liability Post-Delivery[/b]", isListItem: false },
                    { tabLevel: 1, text: "[b]1) Client's Acceptance of Ownership:[/b]", isListItem: false },
                    { tabLevel: 2, text: "Upon the final approval of deliverables, the platform considers the project complete, and the ownership is transferred to the Client.", isListItem: true },
                    { tabLevel: 2, text: "The Client assumes full responsibility for the deliverables and any associated risks or liabilities, including their use, maintenance, and further modifications.", isListItem: true },
                    { tabLevel: 1, text: "[b]2) Platform's Non-Liability Post-Delivery:[/b]", isListItem: false },
                    { tabLevel: 2, text: "After the deliverables are handed over and the revision period has ended, the platform will no longer be liable for any errors, omissions, or unmet expectations unless they were explicitly raised during the review period.", isListItem: true },
                    { text: "‎ ", isListItem: false },

                    { text: "[b]7.4 Revision Process[/b]", isListItem: false },
                    { tabLevel: 1, text: "[b]1) Communication for Revisions:[/b]", isListItem: false },
                    { tabLevel: 2, text: "All revision requests must be submitted in writing to the respective Project Mela Team member in contact with them or email us at [a href= 'mailto:contact@projectmela.com']contact@projectmela.com[/a] with the Subject starting with the text ‘REVISION REQUEST’ so as to ensure proper tracking and accountability.", isListItem: true },
                    { tabLevel: 2, text: "The Client is encouraged to provide clear, specific, and detailed feedback to avoid misunderstandings and streamline the revision process.", isListItem: true },
                    { tabLevel: 1, text: "[b]2) Efforts to Meet Expectations:[/b]", isListItem: false },
                    { tabLevel: 2, text: "While the platform strives to deliver high-quality work, results may sometimes deviate from the Client's subjective expectations. In such cases, the platform will make reasonable efforts to address concerns during the revision period. However, perfection is not guaranteed, and dissatisfaction with subjective outcomes does not entitle the Client to further revisions or refunds beyond the agreed scope.", isListItem: false },
                    { text: "‎ ", isListItem: false },

                    { text: "[b]7.5 Revision Terms for Milestone-Based Projects[/b]", isListItem: false },
                    { tabLevel: 1, text: "Each milestone has its own review and revision period of 5 days after the completion of that respective milestone.", isListItem: true },
                    { tabLevel: 1, text: "Revisions for completed milestones must be requested during the specific milestone's review period. Once the milestone is approved, no further revisions for that milestone will be accepted unless treated as a new task.", isListItem: true },
                    { text: "‎ ", isListItem: false }
                ]
            },
            ],
        },

        {
            number: "8.",
            title: "Confidentiality and Non-Disclosure",
            introText: "At Project Mela, we prioritize the protection of sensitive information shared during project collaborations. To maintain confidentiality and ensure trust between all parties, the following terms apply:",
            infoContainers: [{
                heading: "",
                details: [
                    { text: "‎ ", isListItem: false },

                    { text: "[b]8.1 Binding Nature of the NDA[/b]", isListItem: false },
                    { tabLevel: 1, text: "[b]1) Automatic NDA Binding:[/b]", isListItem: false },
                    { tabLevel: 2, text: "All Candidates engaged in projects through the platform are automatically bound by a [a href= 'https://firebasestorage.googleapis.com/v0/b/project-mela.appspot.com/o/Legal%2FNon%20Disclosure%20Agreement%20Project%20Mela.pdf?alt=media&token=f860640b-f09b-4452-901e-b1b5f6f0a8c2']Non-Disclosure Agreement (NDA)[/a]. This NDA is a mandatory agreement designed to safeguard the confidentiality of project-related information.", isListItem: false },
                    { tabLevel: 1, text: "[b]2) Protection of Confidential Information:[/b]", isListItem: false },
                    { tabLevel: 2, text: "The NDA ensures that all information, data, deliverables, and communications associated with the project remain protected and are not:", isListItem: false },
                    { tabLevel: 3, text: " Shared or disclosed to unauthorized third parties.", isListItem: true },
                    { tabLevel: 3, text: " Used for any purposes outside the scope of the agreed project terms.", isListItem: true },
                    { tabLevel: 1, text: "[b]3) Scope of the NDA:[/b]", isListItem: false },
                    { tabLevel: 2, text: "The NDA applies to all project-related elements, including but not limited to:", isListItem: true },
                    { tabLevel: 3, text: " Technical data, source code, and design files.", isListItem: true },
                    { tabLevel: 3, text: " Business strategies, plans, and proprietary methodologies.", isListItem: true },
                    { tabLevel: 3, text: " Any other confidential or sensitive materials shared during the project.", isListItem: true },
                    { text: "‎ ", isListItem: false },

                    { text: "[b]8.2 Consequences of NDA Violations[/b]", isListItem: false },
                    { tabLevel: 1, text: "[b]1) Immediate Action by Project Mela:[/b]", isListItem: false },
                    { tabLevel: 2, text: "In the event of an NDA violation by a Candidate, the platform will take swift action, including but not limited to:", isListItem: true },
                    { tabLevel: 3, text: " Immediate suspension or termination of the Candidate's account.", isListItem: true },
                    { tabLevel: 3, text: " Notification to the affected Client regarding the violation.", isListItem: true },
                    { tabLevel: 1, text: "[b]2) Disclosure of Identity Information:[/b]", isListItem: false },
                    { tabLevel: 2, text: "The platform will provide the affected Client with all relevant information about the Candidate, including:", isListItem: false },
                    { tabLevel: 3, text: " Identity verification documents submitted during registration (e.g., Aadhar Card, PAN Card, Passport, or Voter ID).", isListItem: true },
                    { tabLevel: 3, text: " Any additional details that may assist the Client in pursuing legal or remedial action.", isListItem: true },
                    { text: "‎ ", isListItem: false },

                    { text: "[b]8.3 Platform’s Limited Liability in NDA Breaches[/b]", isListItem: false },
                    { tabLevel: 1, text: "[b]1) No Responsibility for Candidate Violations:[/b]", isListItem: false },
                    { tabLevel: 2, text: "Project Mela serves as an intermediary platform and does not bear responsibility for any NDA violations committed by Candidates. While the platform ensures that all Candidates are bound by the NDA, it cannot guarantee compliance with its terms.", isListItem: false },
                    { tabLevel: 1, text: "[b]2) Client’s Legal Recourse:[/b]", isListItem: false },
                    { tabLevel: 2, text: "In the event of a violation, the platform’s responsibility is limited to providing the affected Client with the necessary information to pursue legal or remedial actions against the Candidate.", isListItem: true },
                    { tabLevel: 2, text: "The Client acknowledges that Project Mela is not liable for any damages, losses, or disputes arising from such violations and will not be involved in legal proceedings or settlement negotiations.", isListItem: true },
                    { tabLevel: 1, text: "[b]3) Candidate’s Accountability:[/b]", isListItem: false },
                    { tabLevel: 2, text: "By engaging in projects through the platform, Candidates accept full responsibility for adhering to the NDA and acknowledge the consequences of non-compliance, including legal action and financial liabilities imposed by the affected Client.", isListItem: false },
                    { text: "‎ ", isListItem: false },

                    { text: "By using the platform, all users agree to these confidentiality and non-disclosure terms. Clients are encouraged to address any concerns regarding NDA compliance through appropriate legal channels, with Project Mela supporting such actions by providing relevant information.", isListItem: false },

                ]
            },],
        },

        {
            number: "9.",
            title: "Termination or Suspension",
            introText: "Project Mela reserves the right to terminate or suspend accounts, projects, or services under specific circumstances to maintain the integrity, fairness, and smooth operation of the platform. The following conditions apply to both Clients and Candidates:",
            infoContainers: [
                {
                    heading: "",
                    details: [

                        { text: "‎ ", isListItem: false },

                        { text: "[b]9.1 Grounds for Termination or Suspension[/b]", isListItem: false },
                        { tabLevel: 1, text: "[b]1) Termination or Suspension of a Candidate:[/b]", isListItem: false },
                        { tabLevel: 2, text: "A Candidate’s account may be suspended or terminated under any of the following circumstances:", isListItem: false },
                        { tabLevel: 3, text: "Lying or Misrepresentation: Providing false information on their resume, misrepresenting their experience, qualifications, or skills.", isListItem: true },
                        { tabLevel: 3, text: "Unethical or Fraudulent Behaviour: Engaging in plagiarism, unauthorized use of copyrighted materials, or fraudulent activities.", isListItem: true },
                        { tabLevel: 3, text: "Non-Compliance with NDAs: Violating confidentiality agreements, sharing sensitive project information, or using deliverables outside the agreed terms.", isListItem: true },
                        { tabLevel: 3, text: "Delays or Non-Performance: Failing to deliver work within the agreed timelines without valid reasons.", isListItem: true },
                        { tabLevel: 3, text: "Soliciting Work Outside the Platform: Contacting Clients for direct transactions outside the platform, bypassing Project Mela’s processes.", isListItem: true },
                        { tabLevel: 3, text: "Inappropriate Conduct: Displaying unprofessional behavior, harassment, or causing disruptions to the project or platform environment.", isListItem: true },

                        { tabLevel: 1, text: "[b]2) Termination or Suspension of a Client:[/b]", isListItem: false },
                        { tabLevel: 2, text: "A Client’s account or project may be terminated or suspended under the following conditions:", isListItem: false },
                        { tabLevel: 3, text: "Non-Payment or Delayed Payment: Failing to pay agreed amounts, delaying milestone payments, or refusing to settle invoices.", isListItem: true },
                        { tabLevel: 3, text: "Requesting Unauthorized Work: Asking Project Mela Team or its members to perform tasks beyond the agreed scope without proper approvals and payments.", isListItem: true },
                        { tabLevel: 3, text: "Breach of Terms: Violating platform terms, including misuse of deliverables or attempting to exploit Candidates.", isListItem: true },
                        { tabLevel: 3, text: "Submission of False Information: Providing inaccurate or misleading project details, budgets, or identity information.", isListItem: true },
                        { tabLevel: 3, text: "Unethical or Malicious Intent: Using the platform for illegal activities, copyright violations, or unethical practices.", isListItem: true },

                        { tabLevel: 1, text: "[b]3) Termination or Suspension of a Client’s Project:[/b]", isListItem: false },
                        { tabLevel: 2, text: "Projects may be terminated if the Client fails to provide the necessary resources, approvals, or timely payments required to proceed.", isListItem: true },
                        { tabLevel: 2, text: "A project may also be terminated if the Client engages in behaviour detrimental to the progress or quality of the work, such as excessive demands outside the agreed scope or unreasonable delays in approvals.", isListItem: true },
                        { text: "‎ ", isListItem: false },

                        { text: "[b]9.2 Termination of Services[/b]", isListItem: false },
                        { tabLevel: 1, text: "[b]1) By the Client:[/b]", isListItem: false },
                        { tabLevel: 2, text: "Clients may terminate the project at any time by providing written notice. However, the Client agrees to pay for all work completed up to the date of termination. This includes any deliverables already in progress.", isListItem: true },
                        { tabLevel: 2, text: "Refunds for incomplete work will be evaluated on a case-by-case basis as per the refund policy outlined in Section 6.", isListItem: true },

                        { tabLevel: 1, text: "[b]2) By the Platform:[/b]", isListItem: false },
                        { tabLevel: 2, text: "The platform reserves the right to terminate services, suspend accounts, or cease work if the Client or Candidate is found in violation of these terms. Reasons include, but are not limited to, breaches of trust, unethical behavior, or failure to fulfill contractual obligations.", isListItem: true },
                        { text: "‎ ", isListItem: false },

                        { text: "[b]9.3 Notification and Appeals[/b]", isListItem: false },
                        { tabLevel: 1, text: "[b]1) Notification of Termination or Suspension:[/b]", isListItem: false },
                        { tabLevel: 2, text: "Affected users will be notified via their registered email regarding the reasons for termination or suspension.", isListItem: true },
                        { tabLevel: 2, text: "In certain cases, warnings or remedial actions may be suggested before initiating termination or suspension.", isListItem: true },

                        { tabLevel: 1, text: "[b]2) Appeals:[/b]", isListItem: false },
                        { tabLevel: 2, text: "Users whose accounts or projects are suspended or terminated may appeal the decision by providing a written explanation or evidence and mail us at [a href='mailto:contact@projectmela.com']contact@projectmela.com[/a] with the subject starting with the words [APPEAL REQUEST].", isListItem: true },
                        { tabLevel: 2, text: "Appeals will be reviewed on a case-by-case basis, and the platform’s decision after review will be final.", isListItem: true },
                        { text: "‎ ", isListItem: false },

                        { text: "[b]9.4 Platform’s Rights[/b]", isListItem: false },
                        { tabLevel: 1, text: "[b]1) Preservation of Integrity:[/b]", isListItem: false },
                        { tabLevel: 2, text: "The platform prioritizes the integrity of its operations and reserves the right to remove any user or terminate any project that poses a threat to its ecosystem.", isListItem: true },
                        { tabLevel: 2, text: "The platform is not obligated to reinstate accounts or projects once terminated or suspended unless explicitly stated otherwise.", isListItem: true },

                        { tabLevel: 1, text: "[b]2) No Liability for Termination or Suspension:[/b]", isListItem: false },
                        { tabLevel: 2, text: "The platform will not be held liable for losses, delays, or disruptions caused by the suspension or termination of services.", isListItem: true },

                    ]
                },],
        },
        {
            number: "10.",
            title: "Dispute Resolution",
            introText: "At Project Mela, we are committed to fostering a collaborative and transparent environment where Clients and Candidates can resolve conflicts amicably.",
            infoContainers: [
                {
                    heading: "",
                    details: [

                        { text: "‎ ", isListItem: false },

                        { text: "If a Client is dissatisfied with the project or its progress, the following steps are available:", isListItem: false },
                        { text: "‎ ", isListItem: false },

                        { text: "[b]1. Replacement of Candidates or Teams:[/b]", isListItem: false },
                        { tabLevel: 1, text: " The platform may, at its sole discretion, replace the assigned Candidate or team with a new one if the work delivered is deemed substandard or does not meet the agreed scope.", isListItem: true },
                        { tabLevel: 1, text: " This decision is made to ensure that the Client’s project is completed satisfactorily while maintaining the platform's quality standards.", isListItem: true },
                        { tabLevel: 1, text: " To initiate the same, kindly drop us an email at [a href='mailto:contact@projectmela.com']contact@projectmela.com[/a]explaining your situation in depth and we would defiently look forward to resolve your issue.", isListItem: true },
                        { text: "‎ ", isListItem: false },

                        { text: "[b]2. Revision Period Utilization:[/b]", isListItem: false },
                        { tabLevel: 1, text: " Clients are encouraged to utilize the 15-day revision period (see Section 7) to request adjustments or corrections to the deliverables.", isListItem: true },
                        { tabLevel: 1, text: " Revisions during this period are strictly limited to the agreed scope and will not include additional features or changes.", isListItem: true },
                        { text: "‎ ", isListItem: false },

                        { text: "[b]3. Escalation to Senior Managers:[/b]", isListItem: false },
                        { tabLevel: 1, text: " If the Client believes their concerns have not been adequately addressed, they may request the involvement of senior managers from the platform which they can do by simply dropping an email about the same at [a href='mailto:contact@projectmela.com']contact@projectmela.com[/a].", isListItem: true },
                        { tabLevel: 1, text: " Senior Managers will conduct a thorough review of the project details, milestones, and communication records to propose a fair resolution.", isListItem: true },
                        { text: "‎ ", isListItem: false }
                    ]
                },
            ],
        },
        {
            number: "11.",
            title: "Limitation of Liability",
            introText: "Project Mela and its parent entity, Teraforge Digital Lab LLP, are committed to providing a reliable platform for Clients and Candidates to collaborate on projects. However, the platform's liability is strictly limited as outlined below. By using the platform, all users agree to these terms:",
            infoContainers: [
                {
                    heading: "",
                    details: [
                        { text: "‎ ", isListItem: false },


                        { text: "[b]11.1 General Liability Limitations[/b]", isListItem: false },
                        { tabLevel: 1, text: "[b]1) No Liability for Indirect or Consequential Damages:[/b]", isListItem: false },
                        { tabLevel: 2, text: " Under no circumstances shall Project Mela or Teraforge Digital Lab LLP be liable for any indirect, incidental, punitive, special, or consequential damages arising from:", isListItem: false },
                        { tabLevel: 3, text: " The use or inability to use the platform.", isListItem: true },
                        { tabLevel: 3, text: " Delays, interruptions, or errors in platform operations.", isListItem: true },
                        { tabLevel: 3, text: " Loss of data, revenue, profits, reputation, or business opportunities, even if advised of the possibility of such damages.", isListItem: true },

                        { tabLevel: 1, text: "[b]2) Limited Responsibility for Services Rendered:[/b]", isListItem: false },
                        { tabLevel: 2, text: " The platform’s role is limited to facilitating communication, collaboration, and project management between Clients and Candidates.", isListItem: true },
                        { tabLevel: 2, text: " All work performed by Candidates is their sole responsibility, and Project Mela is not liable for:", isListItem: true },
                        { tabLevel: 3, text: "➤ The quality, accuracy, or completeness of the deliverables.", isListItem: false },
                        { tabLevel: 3, text: "➤ Delays, errors, or disputes arising from the Candidate’s work.", isListItem: false },
                        { tabLevel: 3, text: "➤ Violations of confidentiality, intellectual property rights, or legal obligations by Candidates.", isListItem: false },

                        { tabLevel: 1, text: "[b]3) Non-Liability for User Disputes:[/b]", isListItem: false },
                        { tabLevel: 2, text: " The platform is not a party to agreements between Clients and Candidates. Any disputes, disagreements, or claims arising between users must be resolved independently, as outlined in Section 10 (Dispute Resolution).", isListItem: false },
                        { text: "‎ ", isListItem: false },

                        { text: "[b]11.2 Specific Exclusions from Liability[/b]", isListItem: false },
                        { tabLevel: 1, text: "[b]1) User Misconduct:[/b]", isListItem: false },
                        { tabLevel: 2, text: " Misrepresentation of skills, qualifications, or experience by Candidates.", isListItem: true },
                        { tabLevel: 2, text: " Fraudulent or unethical actions taken by Clients or Candidates.", isListItem: true },
                        { tabLevel: 2, text: " Malicious intent, copyright infringement, or violations of intellectual property laws by any user.", isListItem: true },

                        { tabLevel: 1, text: "[b]2) Confidentiality Breaches:[/b]", isListItem: false },
                        { tabLevel: 2, text: " While the platform requires all Candidates to adhere to a Non-Disclosure Agreement (NDA), Project Mela is not liable for any breaches of confidentiality or misuse of project-related information.", isListItem: true },
                        { tabLevel: 2, text: " Responsibility for addressing such breaches lies with the aggrieved Client, who may take legal or remedial actions directly against the violator.", isListItem: true },

                        { tabLevel: 1, text: "[b]3) Project Ownership and Post-Delivery:[/b]", isListItem: false },
                        { tabLevel: 2, text: " Once deliverables are handed over and the revision period has concluded, the Client assumes full ownership and responsibility for the project.", isListItem: true },
                        { tabLevel: 2, text: " The platform is not liable for any subsequent issues, liabilities, or disputes related to the project, as outlined in Section 5 (Ownership of Deliverables).", isListItem: true },

                        { tabLevel: 1, text: "[b]4) Platform Access and Functionality:[/b]", isListItem: false },
                        { tabLevel: 2, text: " Any interruptions, technical errors, or downtime affecting platform access.", isListItem: true },
                        { tabLevel: 2, text: " Losses arising from unauthorized access to user accounts or data breaches caused by third-party actions beyond the platform’s control.", isListItem: true },
                        { text: "‎ ", isListItem: false },

                        { text: "[b]11.3 Limitation of Monetary Liability[/b]", isListItem: false },
                        { tabLevel: 1, text: "[b]1) Capped Liability for Services Provided:[/b]", isListItem: false },
                        { tabLevel: 2, text: " In all cases, the platform’s liability, if any, is strictly limited to the amount of fees paid by the Client to the platform for the specific project or service in question.", isListItem: false },

                        { tabLevel: 1, text: "[b]2) No Liability Beyond Scope of Services:[/b]", isListItem: false },
                        { tabLevel: 2, text: " Project Mela is not liable for any issues, losses, or damages arising outside the scope of services explicitly provided through the platform.", isListItem: false },
                        { text: "‎ ", isListItem: false },

                        { text: "[b]11.4 User Acknowledgment[/b]", isListItem: false },
                        { tabLevel: 1, text: "By using Project Mela, all users agree to:", isListItem: false },
                        { tabLevel: 2, text: " Accept the platform’s role as a mediator and facilitator, not as a guarantor of outcomes or services.", isListItem: true },
                        { tabLevel: 2, text: " Acknowledge that the platform has no control over the actions, behavior, or quality of work provided by Candidates or Clients.", isListItem: true },
                        { tabLevel: 2, text: " Release Project Mela and Teraforge Digital Lab LLP from any claims, liabilities, or damages arising from disputes, misconduct, or project-related issues beyond the platform’s reasonable control.", isListItem: true },

                    ]
                },],
        },


        {
            number: "12.",
            title: "Acknowledgement",
            introText: "We reserve the right to modify these Terms at any time. Users will be notified of significant changes. Continued use of the platform constitutes acceptance of the revised Terms.",
            infoContainers: [
                {
                    heading: "",
                    details: [
                        { text: "‎ ", isListItem: false },
                        { text: "[i]By using [/i] [b]Project Mela[/b],  [i] you confirm that you understand and agree to these Terms. Should you have questions, contact us at [/i] [a href='mailto:contact@projectmela.com']contact@projectmela.com[/a].", isListItem: false },
                        { text: "‎ ", isListItem: false },
                        { text: "[b]Company:[/b] [a href='https://teraforgedigitallab.com/']Teraforge Digital Lab LLP[/a].", isListItem: false },
                        { text: "[b]Email:[/b] [a href='mailto:contact@projectmela.com']contact@projectmela.com[/a].", isListItem: false },
                        { text: "[b]Address:[/b] W06 Quality Homes, Lodha Crown, Near Majiwada Flyover, Thane, Maharashtra, India - 400601[/i]", isListItem: false },
                        { text: "‎ ", isListItem: false },




                    ]
                },],
        },

        //Hello World 









    ]
}

