import React, { useEffect, useState } from "react";
import '../../pages/Home.css';
import logo from '../../images/logo2.svg';
import { useNavigate } from 'react-router-dom';
import { auth } from "../../firebaseConfig/Firebase";
import HamburgerButton from "../General/HamburgerButton/HamburgerButton";

const Header = () => {

    const navigate = useNavigate();
    const [isSignedIn, setIsSignedIn] = useState(false);

    const handleSignInClick = () => {
        navigate('/signin');
    };

    const handleLogOutClick = () => {
        auth.signOut().then(() => {
            navigate('/signin');
        });
    };


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setIsSignedIn(true);
            } else {
                setIsSignedIn(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const handlePostJobClick = () => {
        navigate('/list-job');  // Navigate to the job posting page
    };

    return (
        <>
            <header class="c6kdq ckof8 cn582">
                <div class="cfd1l cnsl7 cwkz1 cwkio">
                    <div class="c8og8 caqh0 cduop chu3i c1ymg">

                        {/* <!--  Site branding --> */}
                        <div class="ch3n4 ctzpc">
                            {/* <!--  Logo --> */}
                            <a class="cohq9 c6kou cu1zo" aria-label="Logo" onClick={() => navigate('/')}>
                                <img src={logo} alt="Logo" />
                            </a>

                        </div>
                        <a className="c1laq chgw8 cw2fq cu1zo" onClick={() => navigate('/')}><b>Project Mela</b></a>

                        {/* <!--  Desktop navigation --> */}
                        <nav class="c8og8 cycys">

                            {/* <!--  Desktop sign in links --> */}
                            <ul class="c8og8 cycys cvkqj cduop cemyy">
                                <div className="hide-on-small">
                                    {isSignedIn ? (
                                        <li>
                                            <a
                                                class="c8og8 cduop c50eh cm7rx cf37z c4vrg cw2fq c86o5 cu1zo"

                                                onClick={() => auth.signOut().then(() => navigate('/signin'))}
                                            >
                                                <b>Log out</b>
                                            </a>
                                        </li>
                                    ) : (
                                        <li>
                                            <a
                                                class="c8og8 cduop c50eh cm7rx cf37z c4vrg cw2fq c86o5 cu1zo"

                                                onClick={() => navigate('/signin')}
                                            >
                                                <b>Sign in</b>
                                            </a>
                                        </li>
                                    )}

                                    <li>
                                        <a
                                            class="c8og8 cduop cu1zo"

                                            onClick={() => navigate('/profile')}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#6366f1">
                                                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                                {/* Will Add Notification Page Later */}


                                {/* <li>
                                    <a
                                        class="c8og8 cduop cu1zo"

                                        onClick={() => navigate('/notification')}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#6366f1">

                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />


                                        </svg>
                                    </a>
                                </li> */}



                                <li class="c75a8 hide-on-small">
                                    <div>
                                        <button
                                            className="c0tyr ce7qj cyky3 ctpla czwz3"
                                            onClick={handlePostJobClick}
                                        >
                                            <b>Post a Project</b>
                                        </button>
                                    </div>
                                </li>

                                <li className="c75a8">
                                    <HamburgerButton
                                        isLoggedIn={isSignedIn}
                                        onSignInClick={handleSignInClick}
                                        onLogOutClick={handleLogOutClick}
                                    />
                                </li>

                            </ul>

                        </nav>

                    </div>
                </div>
            </header>

        </>



    )

}

export default Header;