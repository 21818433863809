import React from "react";
import "./GradientButton.css";

const GradientButton = ({ onClick, text = "Button" }) => {
    return (
        <button className="ai_gradient_button_container" onClick={onClick}>
            <span className="ai_gradient_button_text">{text}</span>
        </button>
    );
};

export default GradientButton;
