import React from "react";
import PropTypes from "prop-types";

const InfoContainer = ({ heading, details, isListType = true }) => (
    <div className="terms-and-conditions-infoContainer">
        <h6 className="terms-and-conditions-infoHeading">{heading}</h6>
        <p className="terms-and-conditions-infoDetails">
            {isListType ? (
                <ul>
                    {details.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            ) : (
                details
            )}
        </p>
    </div>
);

InfoContainer.propTypes = {
    heading: PropTypes.string.isRequired,
    details: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    isListType: PropTypes.bool
};

export default InfoContainer;