import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig/Firebase'; // Adjust the path to your Firebase config

export const createUserDocument = async (user, additionalData = {}) => {
    if (!user) return;

    const userRef = doc(db, 'Users', user.email);
    const userData = {
        FirstName: additionalData.firstName || '',
        LastName: additionalData.lastName || '',
        Email: user.email,
        Mobile: 0,
        XP: 0,
        ResumeLink: '',
        Designation: '',
        GithubLink: '',
        LinkedinLink: '',
        InstagramLink: '',
        Skills: [],
        AboutMe: '',
        MyExperience: '',
        PastProjects: '',
        isCookiesAccepted: false,
        profilePic: "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg",
        isProfileCompleted: false,
    };

    try {
        await setDoc(userRef, userData);
    } catch (error) {
        console.error("Error creating user document:", error);
    }
};
